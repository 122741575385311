import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  additionalPaymentField,
  additionalPaymentFieldContainer,
  additionalPaymentFieldLabel,
  additionalPaymentFrequencySelectButton,
  additionalPaymentFrequencySelectButtonContainer,
  additionalPaymentFrequencySelectButtonSelected,
  additionalPaymentFrequencySelectContainer,
  additionalPaymentFrequencySelectTitleText,
  earlyLoanPayoffTileContainer,
  earlyLoanPayoffTileHeaderContainer,
} from "./EarlyLoanPayoffTile.css";
import { badgeContainer } from "../../molecules/LineChartTile/LineChartTile.css";
import { IEarlyLoanPayoffTileProps } from "./types";
import { Loan } from "../../../loan-utils/loan";

export const EarlyLoanPayoffTile: FC<IEarlyLoanPayoffTileProps> = ({
  initialLoanAmount,
  initialInterestRate,
  initialLoanTermYears,
  additionalPaymentFieldLabelText,
}) => {
  const [additionalPayment, setAdditionalPayment] = useState(100);
  // const [additionalPaymentFrequency, setAdditionalPaymentFrequency] =
  //   useState<IEarlyPayOffStrategy["frequency"]>("MONTHLY");
  const [totalSavings, setTotalSavings] = useState("");

  useEffect(() => {
    const loan = new Loan();
    const loanWExtraPayment = new Loan();

    loan.amount = initialLoanAmount;
    loan.interestRate = initialInterestRate;
    loan.years = initialLoanTermYears;
    loan.extraPayment = 0;

    loanWExtraPayment.amount = initialLoanAmount;
    loanWExtraPayment.interestRate = initialInterestRate;
    loanWExtraPayment.years = initialLoanTermYears;
    loanWExtraPayment.extraPayment = additionalPayment;

    const costDiff = loan.totalCost - loanWExtraPayment.totalCost;

    setTotalSavings(
      costDiff.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      })
    );
  }, [
    additionalPayment,
    initialInterestRate,
    initialLoanAmount,
    initialLoanTermYears,
  ]);

  return (
    <div className={earlyLoanPayoffTileContainer}>
      <div className={earlyLoanPayoffTileHeaderContainer}>
        <span>Early Payoff Strategy</span>
        <div
          className={badgeContainer}
          style={{
            marginLeft: "auto",
            marginRight: "22px",
          }}
        >
          <span>Total Savings: {totalSavings}</span>
        </div>
      </div>
      <div className={additionalPaymentFieldContainer}>
        <label
          className={additionalPaymentFieldLabel}
          htmlFor="additionalPaymentAmount"
        >
          {additionalPaymentFieldLabelText}
        </label>
        <input
          name="additionalPaymentAmount"
          className={additionalPaymentField}
          type="number"
          value={additionalPayment}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAdditionalPayment(+e.target.value)
          }
        />
      </div>
      <div className={additionalPaymentFrequencySelectContainer}>
        <span className={additionalPaymentFrequencySelectTitleText}>
          Increase Frequency
        </span>
        <div className={additionalPaymentFrequencySelectButtonContainer}>
          <div>
            <button className={additionalPaymentFrequencySelectButtonSelected}>
              Monthly
            </button>
          </div>
          <div>
            <button className={additionalPaymentFrequencySelectButton}>
              Bi weekly
            </button>
          </div>
          <div>
            <button className={additionalPaymentFrequencySelectButton}>
              Weekly
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
