import React, { FC } from "react";
import { ITotalCostAnalysisReportTemplateProps } from "./types";
import {
  TotalCostAnalysisReportTemplateContainer,
  earlyPayoffStrategyBannerContainer,
  gridLayoutContainer,
  highlightScenarioTileContainer,
  horizontalBrokerInfoTileContainer,
  largeVisualizationContainer,
  leftHandRailContainer,
  monthlyPaymentDonutChartContainer,
  scenarioPreviewTileContainer,
  scenarioSummaryBannerContainer,
  visualizationContainer,
} from "./TotalCostAnalysisReportTemplate.css";
import { useMedia } from "react-use";

export const TotalCostAnalysisReportTemplate: FC<
  ITotalCostAnalysisReportTemplateProps
> = ({
  horizontalBrokerInfoTile,
  scenarioSummaryBanner,
  earlyPayOffStrategyBanner,
  highlightScenarioTile,
  scenarioPreviewTiles,
  monthlyPaymentDonutChart,
  largeVisualization,
  visualizationTiles,
  verticalBrokerInfoRail,
}) => {
  const isLessThan996px = useMedia("(max-width: 996px)");
  return (
    <div className={TotalCostAnalysisReportTemplateContainer}>
      <div className={gridLayoutContainer}>
        {isLessThan996px && (
          <div className={horizontalBrokerInfoTileContainer}>
            {horizontalBrokerInfoTile}
          </div>
        )}
        <div className={scenarioSummaryBannerContainer}>
          {scenarioSummaryBanner}
        </div>
        <div className={earlyPayoffStrategyBannerContainer}>
          {earlyPayOffStrategyBanner}
        </div>
        <div className={highlightScenarioTileContainer}>
          {highlightScenarioTile}
        </div>
        {scenarioPreviewTiles.map((scenarioPreviewTile) => {
          return (
            <div className={scenarioPreviewTileContainer}>
              {scenarioPreviewTile}
            </div>
          );
        })}
        <div className={monthlyPaymentDonutChartContainer}>
          {monthlyPaymentDonutChart}
        </div>
        <div className={largeVisualizationContainer}>{largeVisualization}</div>
        {visualizationTiles.map((visualizationTile) => {
          return (
            <div className={visualizationContainer}>{visualizationTile}</div>
          );
        })}
      </div>
      {!isLessThan996px && (
        <div className={leftHandRailContainer}>{verticalBrokerInfoRail}</div>
      )}
    </div>
  );
};
