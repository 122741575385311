import { object, number, string } from "yup";

const feeDetailShape = object()
  .shape({
    amount: number().required("Amount is required"),
    paidBy: string().required("Paid by is required"),
  })
  .optional();

export const feeDetailFormSchemaShape = {
  hazardInsurance: feeDetailShape,
  hazardInsurancePremium: feeDetailShape,
  taxReserves: feeDetailShape,
  processingFee: feeDetailShape,
  appraisalFee: feeDetailShape,
  floodCertificationFee: feeDetailShape,
  taxServiceFee: feeDetailShape,
  creditReportFee: feeDetailShape,
  lenderTitleInsurance: feeDetailShape,
  settlementFee: feeDetailShape,
  recordingFee: feeDetailShape,
};

export const feeDetailFormSchema = object().shape(feeDetailFormSchemaShape);
