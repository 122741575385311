import "src/tokens.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens.css.ts.vanilla.css\",\"source\":\"Ll8xMjNkdnRxMCB7CiAgZm9udC1mYW1pbHk6IEJpdHRlcjsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBjb2xvcjogIzRFNTE1NjsKfQouXzEyM2R2dHExIHsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fMTIzZHZ0cTIgewogIGZvbnQtZmFtaWx5OiBSYWxld2F5OwogIGZvbnQtc3R5bGU6IG5vcm1hbDsKICBmb250LXNpemU6IDI0cHg7Cn0KLl8xMjNkdnRxMyB7CiAgZm9udC1mYW1pbHk6IFBsYXlmYWlyIERpc3BsYXk7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtc2l6ZTogNTZweDsKICBsaW5lLWhlaWdodDogOTBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fMTIzZHZ0cTQgewogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl8xMjNkdnRxNSB7CiAgZm9udC1mYW1pbHk6IEludGVyOwogIGZvbnQtc2l6ZTogMjhweDsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBsaW5lLWhlaWdodDogbm9ybWFsOwp9Ci5fMTIzZHZ0cTYgewogIGZvbnQtZmFtaWx5OiBJbnRlcjsKICBmb250LXNpemU6IDI4cHg7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtd2VpZ2h0OiA2MDA7CiAgbGluZS1oZWlnaHQ6IG5vcm1hbDsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/pages/TotalCostAnalysisReportPage/TotalCostAnalysisReportPage.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/pages/TotalCostAnalysisReportPage/TotalCostAnalysisReportPage.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W3W6bMBS+71NYmia1UkH+AQL0ZhdrXmNyggG3YCNDmnRT330GQ4IhSZNsmpaLRDnnfMfn5/M5dn8gFXhe8wbBrzsAVlIlTDmKJnxTxwC7BEXV7umg2fKkyWOAXBRY8rp5L1gMalnwZCRey0KqGHx5xs/hEnYKun7NlNyIRIuXy6Ux3jl1ThO5jQGsdgAh148Cj5CARD4OSLBohQuXRF7gIw/hKCBhJ3SI6y3CiEBMvAhG0F/osIDKVvQe+Y8Ak0fg4UcAXRg+PN193LlDuqRLN+F1VdD3GKQF67Jpf52EK7ZuuBQxUHLbinPGs7zRaUP4tf3/sqkbnr7r9ETDhFas9TdTp/KraJJwkTkFS7UtgaZyg1QZ30Y8CtG7LERd4k0pLKTfIUuqMi6cRlYx8MOJ82Bkootu6RZndOHMNe6JMAs0o1o70GRWsS4NJhLLezT3Dk94P1UG69yRazpzjXyLwUc4LzdNwQUb6G7Y/b+Q+cBK7drEO1zOjqWj3Fdd7hPzkX49r03vccCUWtG3zkIm84Z5k4ZlykyE9tdpWKmlDXNMu3S5FasYbe51bihVD3NDfQO1ldbtW4un4bN/F8SRQE6XPe3i6jyplg11RQXAe+/m9F6MLGR2DIkuQeY3I/kVSGwhX25Gvt6MLG5GljcjxZhnZpbrnhsOWOwLp6Nc2qP8MjZ6V7LRm0cyDIZetpJNI8tBPIqvum7V7CmPfTLxhCaLlYt2gjqDR1rwTDhcp1KPl+aZfdqfFEB7IPnW8yMGQgpmT+T922PZfVrlIEnT9Mjg981uX29U3VpVkg8xNIqKmpsaTA/Q45jUgNGaObrCemNMyhHn8o0p87iaxwahH6yIDfEmxtrMw170fTFKYZvrGh6rxzCL/PNbGZ7Yyoe6n+hUqi2dmv9kh9X85wWbttGqxvUFNM+fPUXtG2ruLQ7nlwX1t/ZbyRJOwX1Jd/vnbnfPHzq/k9332S3Gh1t8drV9jF2L3rUdc5/Ix/EYoyiYhcj+ToiBCbFX0k0jz8Sejs/sJiw6C57MXTRzmJ11OODwDJfPcPgiHJ/hyGnc0VYsglvZgm5hyyddtus88UcuZZ+/J99vVm/UozMOAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/organisms/ScenarioTile/ScenarioTile.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/ScenarioTile/ScenarioTile.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Ty26DMBC85yssRT06MoSQQE5to/wH4IW4JTYyjvKo8u815hGeSQ89Ida7OzO7s4tbBna2JuhnhhBleZYGVx/FKVy2OlB8MWUSIsUE91Ek0tORFy8HYMlB+cgi5G07u88WZR/L9MkCShlPcAqxTrE3mWlWR2VZabtl+CWoFOci/HXKFYuvOBJcAdcN8iyIAIegzgC8DaBEpom5XdRQKCWOj3iQsoRjpuCYa126IciWDtvoiDUUztkNHmUp44Ab8V4ZPAYyYZopaXVYmQ5hEH0nUpw49REXHIrsUEgK8vGvhyr079zaeGS/3/ZwnTFcQ6aBcgdkp4v+c96jI6z1VVvILigXKaNovnN3692m7Z3Vqruiyi72qF2WpKN58zfDGi1NkTdeNFBqIF+bJOjP3SZP5l6apBoL6cQab/akm9wJH1fhGqU+sjOj6uAjrwLoe23ox0ZNNHBRl43e19i6aim9dVkdAlgGlJ30DO1lFW7xmJPl+4fz2T6FfXkHCi4Kmx2MTJ8avnWFYzvebt0cz7kai0tI76C8BfGenC0MdupMJ8cmufUyyad9l/dfG8y0PnQFAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var badgeContainer = 'zpe2p7c _123dvtq5';
export var editScenarioButton = 'zpe2p7a _123dvtq6';
export var editScenarioSection = 'zpe2p79';
export var metricTitle = 'zpe2p7d _123dvtq5';
export var metricValue = 'zpe2p7e _123dvtq6';
export var moreInfoButtonContainer = 'zpe2p73';
export var scenarioMetricDescription = 'zpe2p77';
export var scenarioMetricRowContainer = 'zpe2p76 _123dvtq2';
export var scenarioMetricValue = 'zpe2p78';
export var scenarioNameContainer = 'zpe2p72 _123dvtq1 _123dvtq0';
export var scenarioTileContainer = 'zpe2p70 _1r644tv0';
export var scenarioTileHeader = 'zpe2p71';
export var scenarioTileMoreInfoButton = 'zpe2p75 _123dvtq2';
export var scenarioTileRowsScrollableContainer = 'zpe2p7b';
export var scenarioTitleSection = 'zpe2p74';
export var scenarioTypeContainer = 'zpe2p7f _123dvtq6';