import { FC } from "react";
import { IRateBuydownFormInputSetProps } from "./types";
import { RateBuydownFormInputSetContainer } from "./RateBuydownFormInputSet.css";
import { h2FormHeaderText } from "../../../../tokens.css";
import { SideBySideButton } from "../../../atoms/SideBySideButton/SideBySideButton";
import { FormTextInput } from "../../FormTextInput/FormTextInput";

export const RateBuydownFormInputSet: FC<IRateBuydownFormInputSetProps> = ({
  primaryColor,
  accentColor,

  onHomeValueChange,
  onDownPaymentChange,
  onMortgageAmountChange,
  onLoanTermChange,
  onInterestRateChange,
  onFirstPaymentDateChange,
  onExtraPaymentChange,
  onRateBuyDownPercentageChange,
  onRateBuyDownChange,
}) => {
  return (
    <div className={RateBuydownFormInputSetContainer}>
      <h2 className={h2FormHeaderText}>Loan Details</h2>
      <FormTextInput
        label={"Home Value"}
        required={false}
        inputBorderColor={primaryColor}
        onInputChange={onHomeValueChange}
        name={""}
      />
      <FormTextInput
        label={"Down Payment"}
        required={false}
        inputBorderColor={primaryColor}
        unitSelectorButton={
          <SideBySideButton
            buttonBackgroundColor={accentColor}
            buttonActiveColor={primaryColor}
            leftButtonTitle={"$"}
            rightButtonTitle={"%"}
            onLeftButtonClick={() => {
              console.log("Borrower Selected");
            }}
            onRightButtonClick={() => {
              console.log("Seller Selected");
            }}
          />
        }
        onInputChange={onDownPaymentChange}
        name={""}
      />
      <FormTextInput
        label={"Mortgage Amount"}
        required={false}
        inputBorderColor={primaryColor}
        onInputChange={onMortgageAmountChange}
        name={""}
      />
      <FormTextInput
        label={"Loan Term"}
        required={false}
        inputBorderColor={primaryColor}
        unitSelectorButton={
          <SideBySideButton
            buttonBackgroundColor={accentColor}
            buttonActiveColor={primaryColor}
            leftButtonTitle={"Year"}
            rightButtonTitle={"Month"}
            onLeftButtonClick={() => {
              console.log("Borrower Selected");
            }}
            onRightButtonClick={() => {
              console.log("Seller Selected");
            }}
          />
        }
        onInputChange={onLoanTermChange}
        name={""}
      />
      <FormTextInput
        label={"Interest Rate"}
        required={false}
        inputBorderColor={primaryColor}
        onInputChange={onInterestRateChange}
        name={""}
      />
      <FormTextInput
        label={"First Payment Date"}
        required={false}
        inputBorderColor={primaryColor}
        onInputChange={onFirstPaymentDateChange}
        name={""}
      />
      <h2 className={h2FormHeaderText}>Rate Buy Down</h2>
      <FormTextInput
        label={"Rate Buy Down Amount"}
        required={false}
        inputBorderColor={primaryColor}
        onInputChange={onRateBuyDownPercentageChange}
        name={""}
      />
      <FormTextInput
        label={"Temporary Buy Down Fee"}
        required={false}
        unitSelectorButton={
          <SideBySideButton
            buttonBackgroundColor={accentColor}
            buttonActiveColor={primaryColor}
            leftButtonTitle={"Borrower"}
            rightButtonTitle={"Seller"}
            onLeftButtonClick={() => {
              console.log("Borrower Selected");
            }}
            onRightButtonClick={() => {
              console.log("Seller Selected");
            }}
          />
        }
        inputBorderColor={primaryColor}
        onInputChange={onRateBuyDownChange}
        name={""}
      />

      <h2 className={h2FormHeaderText}>Early Payoff Strategy</h2>
      <FormTextInput
        label={"Extra Payment (monthly)"}
        required={false}
        inputBorderColor={primaryColor}
        onInputChange={onExtraPaymentChange}
        name={""}
      />
    </div>
  );
};
