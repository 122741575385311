import { FC } from "react";
import { IPropertyInformationFormInputSetProps } from "./types";
import { PropertyInformationFormInputSetContainer } from "./PropertyInformationFormInputSet.css";
import { FormTextInput } from "../../FormTextInput/FormTextInput";
import { SideBySideButton } from "../../../atoms/SideBySideButton/SideBySideButton";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { propertyFormSchema } from "./validators";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

// const initialValues = {
//   scenarioType: undefined,
//   purchasePrice: undefined,
//   streetAddress: undefined,
//   city: undefined,
//   state: undefined,
//   zip: undefined,
//   propertyTax: undefined,
//   homeOwnersInsurance: undefined,
//   homeOwnersAssociation: undefined,
//   energyCosts: undefined,
//   waterCosts: undefined,
// };

export const PropertyInformationFormInputSet: FC<
  IPropertyInformationFormInputSetProps
> = ({
  primaryColor,
  accentColor,
  onPurchasePriceChange,
  onStreetAddressChange,
  onCityChange,
  onStateChange,
  onZipCodeChange,
  onPropertyTaxChange,
  onHomeownersInsuranceChange,
  onHomeownersAssociationChange,
  onEnergyCostChange,
  onWaterCostChange,
  ...props
}) => {
  const formik = useFormik({
    initialValues: {
      scenarioType: undefined,
      purchasePrice: undefined,
      streetAddress: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      propertyTax: undefined,
      homeOwnersInsurance: undefined,
      homeOwnersAssociation: undefined,
      energyCosts: undefined,
      waterCosts: undefined,
    },
    onSubmit: async (values) => {
      await sleep(500);
      alert(JSON.stringify(values, null, 2));
    },
    validationSchema: propertyFormSchema,
  });

  return (
    <div className={PropertyInformationFormInputSetContainer}>
      <FormikProvider value={formik}>
        <Form>
          <div>
            <h2>Property Details</h2>
            <FormTextInput
              label={"Purchase Price"}
              required={false}
              onInputChange={onPurchasePriceChange}
              formikField={<Field name="purchasePrice" />}
              name="purchasePrice"
            />
            <FormTextInput
              label={"Street Address"}
              required={false}
              onInputChange={onStreetAddressChange}
              name={"streetAddress"}
            />
            <FormTextInput
              label={"City"}
              required={false}
              onInputChange={onCityChange}
              name={"city"}
            />
            <FormTextInput
              label={"State"}
              required={false}
              onInputChange={onStateChange}
              name={"state"}
            />
            <FormTextInput
              label={"Zip Code"}
              required={false}
              onInputChange={onZipCodeChange}
              name={"zip"}
            />
          </div>
          <div>
            <h2>Taxes, Insurance, Fees, and Utilities</h2>
            <FormTextInput
              label={"Property Tax"}
              required={false}
              unitSelectorButton={
                <SideBySideButton
                  buttonBackgroundColor={accentColor}
                  buttonActiveColor={primaryColor}
                  leftButtonTitle={"$"}
                  rightButtonTitle={"%"}
                  onLeftButtonClick={() => {
                    console.log("Borrower Selected");
                  }}
                  onRightButtonClick={() => {
                    console.log("Seller Selected");
                  }}
                />
              }
              onInputChange={onPropertyTaxChange}
              name={"propertyTax"}
            />
            <FormTextInput
              label={"Homeowner's Insurance"}
              required={false}
              unitSelectorButton={
                <SideBySideButton
                  buttonBackgroundColor={accentColor}
                  buttonActiveColor={primaryColor}
                  leftButtonTitle={"$"}
                  rightButtonTitle={"%"}
                  onLeftButtonClick={() => {
                    console.log("Borrower Selected");
                  }}
                  onRightButtonClick={() => {
                    console.log("Seller Selected");
                  }}
                />
              }
              onInputChange={onHomeownersInsuranceChange}
              name={"homeOwnersInsurance"}
            />
            <FormTextInput
              label={"Home Owner's Association"}
              required={false}
              unitSelectorButton={
                <SideBySideButton
                  buttonBackgroundColor={accentColor}
                  buttonActiveColor={primaryColor}
                  leftButtonTitle={"$"}
                  rightButtonTitle={"%"}
                  onLeftButtonClick={() => {
                    console.log("Borrower Selected");
                  }}
                  onRightButtonClick={() => {
                    console.log("Seller Selected");
                  }}
                />
              }
              onInputChange={onHomeownersAssociationChange}
              name={"homeOwnersAssociation"}
            />
            <div>
              <FormTextInput
                label={"Energy Costs"}
                required={false}
                onInputChange={onEnergyCostChange}
                name={"energyCosts"}
              />
              <FormTextInput
                label={"Water Costs"}
                required={false}
                onInputChange={onWaterCostChange}
                name={"waterCosts"}
              />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};
