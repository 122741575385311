export const orangeCountyHistoricalHomePrices = [
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-07-01",
    value: "809292.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-08-01",
    value: "799000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-09-01",
    value: "799000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-10-01",
    value: "799500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-11-01",
    value: "799973.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-12-01",
    value: "824900.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-01-01",
    value: "825000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-02-01",
    value: "854450.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-03-01",
    value: "892450.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-04-01",
    value: "895000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-05-01",
    value: "885000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-06-01",
    value: "875000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-07-01",
    value: "865444.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-08-01",
    value: "867444.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-09-01",
    value: "879900.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-10-01",
    value: "862450.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-11-01",
    value: "867975.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-12-01",
    value: "879000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-01-01",
    value: "899000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-02-01",
    value: "913509.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-03-01",
    value: "925000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-04-01",
    value: "927000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-05-01",
    value: "906722.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-06-01",
    value: "897000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-07-01",
    value: "887000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-08-01",
    value: "849500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-09-01",
    value: "830000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-10-01",
    value: "817000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-11-01",
    value: "807750.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-12-01",
    value: "799970.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-01-01",
    value: "812500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-02-01",
    value: "813400.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-03-01",
    value: "832814.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-04-01",
    value: "849900.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-05-01",
    value: "863188.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-06-01",
    value: "874900.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-07-01",
    value: "875000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-08-01",
    value: "878818.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-09-01",
    value: "884545.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-10-01",
    value: "887864.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-11-01",
    value: "889500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-12-01",
    value: "899000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-01-01",
    value: "921400.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-02-01",
    value: "945000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-03-01",
    value: "954950.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-04-01",
    value: "899000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-05-01",
    value: "900000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-06-01",
    value: "927900.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-07-01",
    value: "933722.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-08-01",
    value: "935000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-09-01",
    value: "925000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-10-01",
    value: "925000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-11-01",
    value: "939572.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-12-01",
    value: "946940.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-01-01",
    value: "949000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-02-01",
    value: "967495.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-03-01",
    value: "975854.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-04-01",
    value: "990000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-05-01",
    value: "960000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-06-01",
    value: "966125.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-07-01",
    value: "965750.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-08-01",
    value: "949950.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-09-01",
    value: "957500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-10-01",
    value: "975000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-11-01",
    value: "992500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-12-01",
    value: "996775.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-01-01",
    value: "1019000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-02-01",
    value: "1071250.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-03-01",
    value: "1100000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-04-01",
    value: "1148000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-05-01",
    value: "1176000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-06-01",
    value: "1178475.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-07-01",
    value: "1100000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-08-01",
    value: "1100000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-09-01",
    value: "1109500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-10-01",
    value: "1100000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-11-01",
    value: "1097500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-12-01",
    value: "1099000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-01-01",
    value: "1099347.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-02-01",
    value: "1132502.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-03-01",
    value: "1187250.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-04-01",
    value: "1249999.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-05-01",
    value: "1281500.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-06-01",
    value: "1299000.0",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-07-01",
    value: "1339000.0",
  },
];
