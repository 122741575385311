import React, { FC } from "react";
import { IVeteranAffairsScenarioFormInputSetProps } from "./types";
import { VeteranAffairsScenarioFormInputSetContainer } from "./VeteranAffairsScenarioFormInputSet.css";
import { FormTextInput } from "../../FormTextInput/FormTextInput";
import { SideBySideButton } from "../../../atoms/SideBySideButton/SideBySideButton";
import { FormSideBySideButtonInput } from "../../FormSideBySideButtonInput/FormSideBySideButtonInput";
import { h2FormHeaderText } from "../../../../tokens.css";

export const VeteranAffairsScenarioFormInputSet: FC<
  IVeteranAffairsScenarioFormInputSetProps
> = ({
  // Dynamic Colors
  primaryColor,
  accentColor,

  // On input change handlers
  onHomeValueChange,
  onDownPaymentChange,
  onMortgageAmountChange,
  onLoanTermChange,
  onInterestRateChange,
  onVeteranAffairsFundingFeeChange,
  onFinalMortgageAmountChange,
  onFirstPaymentDateChange,

  // Extra Payments
  onExtraPaymentChange,
  onPaymentFrequencyChange,
}) => {
  return (
    <div className={VeteranAffairsScenarioFormInputSetContainer}>
      <h2 className={h2FormHeaderText}>Loan Details</h2>
      <FormTextInput
        label={"Home Value"}
        required={false}
        onInputChange={onHomeValueChange}
        name={""}
      />
      <FormTextInput
        label={"Down Payment"}
        required={false}
        unitSelectorButton={
          <SideBySideButton
            buttonBackgroundColor={accentColor}
            buttonActiveColor={primaryColor}
            leftButtonTitle={"$"}
            rightButtonTitle={"%"}
            onLeftButtonClick={() => {}}
            onRightButtonClick={() => {}}
          />
        }
        onInputChange={onDownPaymentChange}
        name={""}
      />
      <FormTextInput
        label={"Base Mortgage Amount"}
        required={false}
        onInputChange={onMortgageAmountChange}
        name={""}
      />
      <FormTextInput
        label={"Loan Term"}
        required={false}
        unitSelectorButton={
          <SideBySideButton
            buttonBackgroundColor={accentColor}
            buttonActiveColor={primaryColor}
            leftButtonTitle={"Year"}
            rightButtonTitle={"Month"}
            onLeftButtonClick={() => {}}
            onRightButtonClick={() => {}}
          />
        }
        onInputChange={onLoanTermChange}
        name={""}
      />
      <FormTextInput
        label={"Interest Rate"}
        required={false}
        onInputChange={onInterestRateChange}
        name={""}
      />
      <FormTextInput
        label={"VA Funding Fee"}
        required={false}
        onInputChange={onVeteranAffairsFundingFeeChange}
        name={""}
      />
      <FormTextInput
        label={"Final Mortgage Amount"}
        required={false}
        onInputChange={onFinalMortgageAmountChange}
        name={""}
      />
      <FormTextInput
        label={"First Payment Date"}
        required={false}
        onInputChange={onFirstPaymentDateChange}
        name={""}
      />
      <h2 className={h2FormHeaderText}>Early Payoff Strategy</h2>
      <FormTextInput
        label={"Extra Payment (monthly)"}
        required={false}
        onInputChange={onExtraPaymentChange}
        name={""}
      />
      <FormSideBySideButtonInput
        label={"Payment Frequency"}
        leftButtonText={"Year"}
        rightButtonText={"Month"}
        onLeftButtonClick={onPaymentFrequencyChange}
        onRightButtonClick={onPaymentFrequencyChange}
        primaryColor={primaryColor}
      />
    </div>
  );
};
