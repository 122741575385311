import React, { FC } from "react";
import { IBadgeProps } from "./types";
import { BadgeContainer, badgeText } from "./Badge.css";

export const Badge: FC<IBadgeProps> = ({ text, color }) => {
  return (
    <div className={BadgeContainer} style={{ background: color }}>
      <span className={badgeText}>{text}</span>
    </div>
  );
};
