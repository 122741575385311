import { FC, useCallback, useState } from "react";
import { ICreateNewScenarioFormProps } from "./types";
import {
  CreateNewScenarioFormContainer,
  createScenarioButton,
  createScenarioButtonContainer,
  formAccentBar,
  formInputsContainer,
} from "./CreateNewScenarioForm.css";
import { PropertyInformationFormInputSet } from "../FormInputSets/PropertyInformationFormInputSet/PropertyInformationFormInputSet";
import { FeeDetailsFormInputSet } from "../FormInputSets/FeeDetailsFormInputSet/FeeDetailsFormInputSet";
import {
  createEmptyLendingScenario,
  getFormInputSetByScenarioType,
  onAppraisalFeeChange,
  onCityChange,
  onCreditReportFeeChange,
  onEnergyCostChange,
  onFloodCertificationFeeChange,
  onHazardInsurancePremiumChange,
  onHazardInsuranceReservesChange,
  onHomeownersAssociationChange,
  onHomeownersInsuranceChange,
  onLenderTitleInsuranceFeeChange,
  onProcessingFeeChange,
  onPropertyTaxChange,
  onPurchasePriceChange,
  onRateBuydownFeeChange,
  onRecordingFeeChange,
  onSettlementFeeChange,
  onStateChange,
  onStreetAddressChange,
  onTaxReservesChange,
  onTaxServiceFeeChange,
  onWaterCostChange,
  onZipCodeChange,
} from "./utils";
import { ILendingScenario, IScenarioType } from "../../../types";
import { Chip, Modal, Stepper, Title } from "@mantine/core";
import { morfiDarkPurple } from "../../../tokens.css";
import { loanScenarioTypes } from "./constants";

// Property
// onPurchasePriceChange,
// onStreetAddressChange,
// onCityChange,
// onStateChange,
// onZipCodeChange,
// onPropertyTaxChange,
// onHomeownersInsuranceChange,
// onHomeownersAssociationChange,
// onEnergyCostChange,
// onWaterCostChange,

// // Loan
// onHomeValueChange,
// onDownPaymentChange,
// onMortgageAmountChange,
// onLoanTermChange,
// onInterestRateChange,
// onVeteranAffairsFundingFeeChange,
// onFinalMortgageAmountChange,
// onFirstPaymentDateChange,

// // Fees
// onHazardInsuranceReservesChange,
// onHazardInsurancePremiumChange,
// onTaxReservesChange,
// onProcessingFeeChange,
// onAppraisalFeeChange,
// onFloodCertificationFeeChange,
// onTaxServiceFeeChange,
// onCreditReportFeeChange,
// onLenderTitleInsuranceFeeChange,
// onSettlementFeeChange,
// onRecordingFeeChange,
// onRateBuydownFeeChange,

export const CreateNewScenarioForm: FC<ICreateNewScenarioFormProps> = ({
  title,
  scenarioType,
  primaryColor,
  accentColor,
  steps = ["Property", "Loan", "Fees"],
  // activeStepIdx,
  // onNextStepClick,
  // onStepIdxClick,

  // Callback to save scenario to report object
  onCreateScenario,

  isCreateLendingScenarioFormVisible,
  createLendingScenarioFormHandlers,

  children,
  ...props
}) => {
  const [tmpScenario, setTmpScenario] = useState<ILendingScenario>(
    createEmptyLendingScenario("PURCHASE" as IScenarioType)
  );

  const [activeStepIdx, setActiveStepIdx] = useState(1);

  const onNextStepClick = useCallback(() => {
    if (activeStepIdx < steps.length - 1) {
      setActiveStepIdx((state) => state + 1);
    }
  }, [activeStepIdx, steps.length]);

  // const onStepIdxClick = useCallback(
  //   (stepIdx: number) => {
  //     setActiveStepIdx((state) => {
  //       if (stepIdx >= 0 && stepIdx <= steps.length - 1) return stepIdx;
  //       return state;
  //     });
  //   },
  //   [steps.length]
  // );

  return (
    <Modal.Root
      opened={isCreateLendingScenarioFormVisible}
      onClose={() => {
        createLendingScenarioFormHandlers.close();
      }}
      size="auto"
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              className={formAccentBar}
              style={{ backgroundColor: primaryColor, marginTop: "-20px" }}
            ></div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Modal.Title>
                <Title order={2}>Create Scenario</Title>
              </Modal.Title>
              <Modal.CloseButton />
            </div>
            <div>
              <Stepper
                color={morfiDarkPurple}
                size="xs"
                active={activeStepIdx}
                onStepClick={setActiveStepIdx}
              >
                <Stepper.Step
                  label="Property"
                  description="Enter property details"
                ></Stepper.Step>
                <Stepper.Step
                  label="Loan"
                  description="Enter loan details"
                ></Stepper.Step>
                <Stepper.Step
                  label="Fees"
                  description="Enter fee details"
                ></Stepper.Step>
                <Stepper.Completed>
                  Completed, click back button to get to previous step
                </Stepper.Completed>
              </Stepper>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                flexWrap: "wrap",
                maxWidth: "600px",
                marginTop: "15px",
              }}
            >
              <Chip.Group
                onChange={(value) => {
                  setTmpScenario((tmpScenario: any): ILendingScenario => {
                    return {
                      ...tmpScenario,
                      scenarioType: value as IScenarioType,
                    };
                  });
                }}
              >
                {loanScenarioTypes.map((scenarioType) => {
                  return (
                    <Chip
                      checked={scenarioType === tmpScenario.scenarioType}
                      variant="filled"
                      size="xs"
                      color={morfiDarkPurple}
                      value={scenarioType}
                    >
                      {scenarioType.replaceAll("_", " ")}
                    </Chip>
                  );
                })}
              </Chip.Group>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={CreateNewScenarioFormContainer}>
            <div className={formInputsContainer}>
              {activeStepIdx === 0 ? (
                <PropertyInformationFormInputSet
                  primaryColor={primaryColor}
                  accentColor={accentColor}
                  onPurchasePriceChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onPurchasePriceChange(value, state)
                    )
                  }
                  onStreetAddressChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onStreetAddressChange(value, state)
                    )
                  }
                  onCityChange={(value: any) =>
                    setTmpScenario((state: any) => onCityChange(value, state))
                  }
                  onStateChange={(value: any) =>
                    setTmpScenario((state: any) => onStateChange(value, state))
                  }
                  onZipCodeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onZipCodeChange(value, state)
                    )
                  }
                  onPropertyTaxChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onPropertyTaxChange(value, state)
                    )
                  }
                  onHomeownersInsuranceChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onHomeownersInsuranceChange(value, state)
                    )
                  }
                  onHomeownersAssociationChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onHomeownersAssociationChange(value, state)
                    )
                  }
                  onEnergyCostChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onEnergyCostChange(value, state)
                    )
                  }
                  onWaterCostChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onWaterCostChange(value, state)
                    )
                  }
                />
              ) : null}
              {activeStepIdx === 1
                ? getFormInputSetByScenarioType(scenarioType, {
                    primaryColor: primaryColor,
                    accentColor: accentColor,
                    setTmpScenario: setTmpScenario,
                  })
                : null}
              {activeStepIdx === 2 ? (
                <FeeDetailsFormInputSet
                  primaryColor={primaryColor}
                  accentColor={accentColor}
                  onHazardInsuranceReservesChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onHazardInsuranceReservesChange(value, state)
                    )
                  }
                  onHazardInsurancePremiumChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onHazardInsurancePremiumChange(value, state)
                    )
                  }
                  onTaxReservesChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onTaxReservesChange(value, state)
                    )
                  }
                  onProcessingFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onProcessingFeeChange(value, state)
                    )
                  }
                  onAppraisalFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onAppraisalFeeChange(value, state)
                    )
                  }
                  onFloodCertificationFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onFloodCertificationFeeChange(value, state)
                    )
                  }
                  onTaxServiceFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onTaxServiceFeeChange(value, state)
                    )
                  }
                  onCreditReportFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onCreditReportFeeChange(value, state)
                    )
                  }
                  onLenderTitleInsuranceFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onLenderTitleInsuranceFeeChange(value, state)
                    )
                  }
                  onSettlementFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onSettlementFeeChange(value, state)
                    )
                  }
                  onRecordingFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onRecordingFeeChange(value, state)
                    )
                  }
                  onRateBuydownFeeChange={(value: any) =>
                    setTmpScenario((state: any) =>
                      onRateBuydownFeeChange(value, state)
                    )
                  }
                />
              ) : null}
            </div>
            <div className={createScenarioButtonContainer}>
              <button
                className={createScenarioButton}
                style={{ backgroundColor: primaryColor }}
                onClick={() => {
                  if (activeStepIdx < steps.length - 1) {
                    onNextStepClick();
                  } else if (activeStepIdx === steps.length - 1) {
                    onCreateScenario(tmpScenario);
                  }
                }}
              >
                {activeStepIdx < steps.length - 1 ? "Next" : "Create"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
