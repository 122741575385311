import React, { FC } from "react";
import { IVeteranAffairsScenarioHighlightTileProps } from "./types";
import { VeteranAffairsScenarioHighlightTileContainer } from "./VeteranAffairsScenarioHighlightTile.css";
import { IEarlyPayOffStrategy } from "../../../../types";
import { AccordianItem } from "../../AccordianItem/AccordianItem";
import {
  propertyDetailsSection,
  scenarioHighlightTileDetailSectionH2Style,
  scenarioHighlightTileDetailSectionTextLabel,
  scenarioHighlightTileDetailSectionText,
  earlyPayoffStrategySection,
} from "../PurchaseScenarioHighlightTile/PurchaseScenarioHighlightTile.css";

export const VeteranAffairsScenarioHighlightTile: FC<
  IVeteranAffairsScenarioHighlightTileProps
> = ({ lendingScenario, primaryColor, accentColor }) => {
  return (
    <div className={VeteranAffairsScenarioHighlightTileContainer}>
      <div className={propertyDetailsSection}>
        <h2
          className={scenarioHighlightTileDetailSectionH2Style}
          style={{ color: primaryColor }}
        >
          Property
        </h2>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Property Address
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.property.streetAddress},{" "}
              {lendingScenario.details.property.city},{" "}
              {lendingScenario.details.property.state}{" "}
              {lendingScenario.details.property.zipcode}
            </p>
          </div>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Purchase Price
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.property.purchasePrice}
            </p>
          </div>
        </div>
      </div>
      <div>
        <h2
          className={scenarioHighlightTileDetailSectionH2Style}
          style={{ color: primaryColor }}
        >
          Loan Details
        </h2>
        <div style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Home Value
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.homeValue}
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Interest Rate
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.loan.interestRate}%
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "130px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Veteran Affairs Funding Fee
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              $
              {
                lendingScenario.details.loan.fees.veteransAffairFundingFee
                  .amount
              }
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "110px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Down Payment
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.downPaymentAmount}
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Loan Term
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.loan.term}yrs
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "110px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Base Mortgage Amount
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.finalMortgageAmount}
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              First Payment Date
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.firstPaymentDate}
            </p>
          </div>
        </div>
      </div>
      <div style={{}}>
        {lendingScenario.details.earlyPayoffStrategies && (
          <div className={earlyPayoffStrategySection}>
            <h2
              className={scenarioHighlightTileDetailSectionH2Style}
              style={{ color: primaryColor }}
            >
              Early Payoff Strategy
            </h2>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              {lendingScenario.details.earlyPayoffStrategies.map(
                (earlyPayoffStrategy: IEarlyPayOffStrategy) => {
                  return (
                    <>
                      {" "}
                      <div>
                        <h6
                          className={
                            scenarioHighlightTileDetailSectionTextLabel
                          }
                        >
                          Extra Payment Amount
                        </h6>
                        <p className={scenarioHighlightTileDetailSectionText}>
                          ${earlyPayoffStrategy.extraPaymentAmount}
                        </p>
                      </div>
                      <div>
                        <h6
                          className={
                            scenarioHighlightTileDetailSectionTextLabel
                          }
                        >
                          Frequency
                        </h6>
                        <p className={scenarioHighlightTileDetailSectionText}>
                          {earlyPayoffStrategy.frequency}
                        </p>
                      </div>
                      <div>
                        <h6
                          className={
                            scenarioHighlightTileDetailSectionTextLabel
                          }
                        >
                          Total Savings
                        </h6>
                        <p className={scenarioHighlightTileDetailSectionText}>
                          ${earlyPayoffStrategy.extraPaymentAmount}
                        </p>
                      </div>
                    </>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <AccordianItem
            title={"Taxes, Insurance, and Fees"}
            primaryColor={primaryColor}
            accentColor={accentColor}
            children={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Private Mortgage Insurance
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Hazard Insurance Premium
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Appraisal Fee
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Credit Report
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Recording Fee
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Rate Buy Down Fee (SELLER)
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Tax Reserves
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Flood Certification
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Title Insurance
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Hazard Insurance Reserves
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Processing Fee
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Tax Service
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
                <div style={{ width: "125px" }}>
                  <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                    Settlement Fee
                  </h6>
                  <p className={scenarioHighlightTileDetailSectionText}>$200</p>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};
