import { FC } from "react";
import { IMonthlyPaymentDonutChart } from "./types";
import { DonutChart } from "../../molecules/DonutChart/DonutChart";
import {
  donutChartAndLegendContainer,
  donutChartContainer,
  donutChartSubtext,
  donutChartText,
  graphKeyContainer,
  graphKeyOption,
  graphKeyOptionColor,
  graphKeyOptionContainer,
  graphKeyOptionTextContainer,
  monthlyPaymentTileContainer,
  monthlyPaymentTileTitle,
  optionLabel,
  optionValue,
} from "./MonthlyPaymentDonutChart.css";

export const MonthlyPaymentDonutChart: FC<IMonthlyPaymentDonutChart> = ({
  dataLabel,
  dataBackgroundColors,
  dataValues,
  centerText,
  ...props
}) => {
  return (
    <div className={monthlyPaymentTileContainer}>
      <div>
        <h2 className={monthlyPaymentTileTitle}>Payment Breakdown</h2>
      </div>
      <div className={donutChartAndLegendContainer}>
        <div className={donutChartContainer}>
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <DonutChart
              dataLabel={dataLabel}
              dataBackgroundColors={dataBackgroundColors}
              dataValues={dataValues}
              centerText={centerText}
            />
            <div className={donutChartText}>
              <p style={{ margin: 0 }}>{centerText}</p>
              <p className={donutChartSubtext}>per month</p>
            </div>
          </div>
        </div>
        <ul className={graphKeyContainer}>
          {dataValues.map((valuePair, i) => (
            <li className={graphKeyOption}>
              <div className={graphKeyOptionContainer}>
                <div
                  className={graphKeyOptionColor}
                  style={{ background: dataBackgroundColors[i] }}
                />
                <div className={graphKeyOptionTextContainer}>
                  <span className={optionLabel}>{valuePair.label}</span>
                  <span className={optionValue}>(${valuePair.value})</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
