import { ILendingScenario, IScenarioType } from "../../../types";
import { PurchaseScenarioHighlightTile } from "../../molecules/ScenarioHighlightTileDetailSets/PurchaseScenarioHighlightTile/PurchaseScenarioHighlightTile";
import { RateBuyDownScenarioHighlightTile } from "../../molecules/ScenarioHighlightTileDetailSets/RateBuyDownScenarioHighlightTile/RateBuyDownScenarioHighlightTile";
import { RefinanceScenarioHighlightTile } from "../../molecules/ScenarioHighlightTileDetailSets/RefinanceScenarioHighlightTile/RefinanceScenarioHighlightTile";
import { RentVsBuyScenarioHighlightTile } from "../../molecules/ScenarioHighlightTileDetailSets/RentVsBuyScenarioHighlightTile/RentVsBuyScenarioHighlightTile";
import { VeteranAffairsScenarioHighlightTile } from "../../molecules/ScenarioHighlightTileDetailSets/VeteranAffairsScenarioHighlightTile/VeteranAffairsScenarioHighlightTile";

export const getScenarioHighlightDetailsByScenarioType = (
  lendingScenario: ILendingScenario,
  primaryColor: string,
  accentColor: string
) => {
  if (lendingScenario.scenarioType === "PURCHASE")
    return (
      <PurchaseScenarioHighlightTile
        lendingScenario={lendingScenario}
        primaryColor={primaryColor}
        accentColor={accentColor}
      />
    );
  if (lendingScenario.scenarioType === "REFINANCE")
    return (
      <RefinanceScenarioHighlightTile
        lendingScenario={lendingScenario}
        primaryColor={primaryColor}
        accentColor={accentColor}
      />
    );
  if (lendingScenario.scenarioType === "RATE_BUYDOWN")
    return <RateBuyDownScenarioHighlightTile />;
  if (lendingScenario.scenarioType === "VA")
    return (
      <VeteranAffairsScenarioHighlightTile
        lendingScenario={lendingScenario}
        primaryColor={primaryColor}
        accentColor={accentColor}
      />
    );
  if (lendingScenario.scenarioType === "RENT_VS_BUY")
    return (
      <RentVsBuyScenarioHighlightTile
        lendingScenario={lendingScenario}
        primaryColor={primaryColor}
        accentColor={accentColor}
      />
    );
};

export const getBadgeTextByScenarioLendingType = (
  scenarioType: IScenarioType
) => {
  if (scenarioType === "VA") return "Veteran Affairs";
  if (scenarioType === "PURCHASE") return "Purchase";
  if (scenarioType === "CASHOUT_REFINANCE") return "Cash Out Refinance";
  if (scenarioType === "REFINANCE") return "Refinance";
  if (scenarioType === "REVERSE") return "Reverse";
  if (scenarioType === "RENT_VS_BUY") return "Rent Vs. Buy";
  return scenarioType;
};
