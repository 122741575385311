import { FC, useRef, useState } from "react";
import { IVideoTileProps } from "./types";
import {
  VideoTileContainer,
  controlsButton,
  controlsContainer,
  playVideoButton,
  progressBar,
  progressIndicator,
  timeDisplayText,
  videoContainer,
  videoPlayer,
} from "./VideoTile.css";
import { formatSeconds } from "./utils";

export const VideoTile: FC<IVideoTileProps> = ({
  videoSrc,
  previewSrc,
  videoTrackSrc,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  // const [videoPaused, setVideoPaused] = useState(false);
  // const [videoEnded, setVideoEnded] = useState(false);
  // const [videoPlaying, setVideoPlaying] = useState(true);
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);

  const onTimeUpdate = () => {
    let ref = videoRef.current;
    if (ref) {
      setVideoDuration(ref.duration);
      setVideoCurrentTime(ref.currentTime);
    }
  };

  return (
    <div className={VideoTileContainer}>
      <div className={videoContainer}>
        <div className={progressBar}>
          <div
            className={progressIndicator}
            style={{
              width: `${(videoCurrentTime / videoDuration) * 100}%`,
            }}
          ></div>
        </div>
        <video
          ref={videoRef}
          className={videoPlayer}
          autoPlay={false}
          muted={false}
          data-qa="media-player"
          data-testid="media-player-video"
          id="player"
          playsInline={undefined}
          preload="auto"
          src={videoSrc}
          onTimeUpdate={onTimeUpdate}
          onPlayingCapture={(e) => console.log(e)}
          onPause={() => {}}
          onClick={() => {
            videoRef.current?.paused || videoRef.current?.ended
              ? videoRef.current.play()
              : videoRef.current?.pause();
          }}
        >
          <track default={false} src={videoTrackSrc}></track>
        </video>
        <div className={controlsContainer}>
          <div className={timeDisplayText}>
            {formatSeconds(videoCurrentTime)} / {formatSeconds(videoDuration)}
          </div>
          <button
            aria-label="Mute"
            title="The audio is on"
            className={controlsButton}
          >
            <div>
              <svg
                fill="none"
                height="14"
                width="8"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 4.375v5.25h3.5L7.875 14V0L3.5 4.375H0z"
                  fill="#000"
                ></path>
              </svg>
            </div>
          </button>
          <button
            aria-label="Subtitles / Closed captions"
            title="Subtitles / Closed captions"
            className={controlsButton}
          >
            <div>CC</div>
          </button>
          <button
            aria-label="Playback speed"
            title="Playback speed"
            className={controlsButton}
          >
            <div>1×</div>
          </button>
          <button
            aria-label="Fullscreen: OFF"
            title="Fullscreen: OFF"
            className={controlsButton}
          >
            <svg
              fill="none"
              height="26"
              width="32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                fill="#000"
                fill-opacity="0.2"
                height="26"
                rx="6"
                width="32"
              ></rect>
              <path
                clip-rule="evenodd"
                d="M26 0H6a6 6 0 00-6 6v14a6 6 0 006 6h20a6 6 0 006-6V6a6 6 0 00-6-6zM2 6a4 4 0 014-4h20a4 4 0 014 4v14a4 4 0 01-4 4H6a4 4 0 01-4-4V6zm4 1a1 1 0 011-1h6a1 1 0 110 2H8v2a1 1 0 11-2 0V7zm12 0a1 1 0 011-1h6a1 1 0 011 1v3a1 1 0 11-2 0V8h-5a1 1 0 01-1-1zm7 8a1 1 0 011 1v3a1 1 0 01-.987 1H19a1 1 0 110-2h5v-2a1 1 0 011-1zM7 15a1 1 0 011 1v2h5a1 1 0 110 2H7a1 1 0 01-1-1v-3a1 1 0 011-1z"
                fill="#fff"
                fill-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {videoRef.current?.paused ||
        videoRef.current?.ended ||
        !(
          videoRef.current?.currentTime && videoRef.current?.currentTime > 0
        ) ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                aria-label="Play"
                title="Play"
                className={playVideoButton}
                onClick={() => {
                  videoRef.current?.paused || videoRef.current?.ended
                    ? videoRef.current.play()
                    : videoRef.current?.pause();
                }}
              >
                <svg
                  fill="none"
                  height="96"
                  width="96"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z"
                    fill="#fff"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    clip-rule="evenodd"
                    d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z"
                    fill="#000"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
