import "src/tokens.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens.css.ts.vanilla.css\",\"source\":\"Ll8xMjNkdnRxMCB7CiAgZm9udC1mYW1pbHk6IEJpdHRlcjsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBjb2xvcjogIzRFNTE1NjsKfQouXzEyM2R2dHExIHsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fMTIzZHZ0cTIgewogIGZvbnQtZmFtaWx5OiBSYWxld2F5OwogIGZvbnQtc3R5bGU6IG5vcm1hbDsKICBmb250LXNpemU6IDI0cHg7Cn0KLl8xMjNkdnRxMyB7CiAgZm9udC1mYW1pbHk6IFBsYXlmYWlyIERpc3BsYXk7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtc2l6ZTogNTZweDsKICBsaW5lLWhlaWdodDogOTBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fMTIzZHZ0cTQgewogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl8xMjNkdnRxNSB7CiAgZm9udC1mYW1pbHk6IEludGVyOwogIGZvbnQtc2l6ZTogMjhweDsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBsaW5lLWhlaWdodDogbm9ybWFsOwp9Ci5fMTIzZHZ0cTYgewogIGZvbnQtZmFtaWx5OiBJbnRlcjsKICBmb250LXNpemU6IDI4cHg7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtd2VpZ2h0OiA2MDA7CiAgbGluZS1oZWlnaHQ6IG5vcm1hbDsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/pages/TotalCostAnalysisReportPage/TotalCostAnalysisReportPage.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/pages/TotalCostAnalysisReportPage/TotalCostAnalysisReportPage.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W3W6bMBS+71NYmia1UkH+AQL0ZhdrXmNyggG3YCNDmnRT330GQ4IhSZNsmpaLRDnnfMfn5/M5dn8gFXhe8wbBrzsAVlIlTDmKJnxTxwC7BEXV7umg2fKkyWOAXBRY8rp5L1gMalnwZCRey0KqGHx5xs/hEnYKun7NlNyIRIuXy6Ux3jl1ThO5jQGsdgAh148Cj5CARD4OSLBohQuXRF7gIw/hKCBhJ3SI6y3CiEBMvAhG0F/osIDKVvQe+Y8Ak0fg4UcAXRg+PN193LlDuqRLN+F1VdD3GKQF67Jpf52EK7ZuuBQxUHLbinPGs7zRaUP4tf3/sqkbnr7r9ETDhFas9TdTp/KraJJwkTkFS7UtgaZyg1QZ30Y8CtG7LERd4k0pLKTfIUuqMi6cRlYx8MOJ82Bkootu6RZndOHMNe6JMAs0o1o70GRWsS4NJhLLezT3Dk94P1UG69yRazpzjXyLwUc4LzdNwQUb6G7Y/b+Q+cBK7drEO1zOjqWj3Fdd7hPzkX49r03vccCUWtG3zkIm84Z5k4ZlykyE9tdpWKmlDXNMu3S5FasYbe51bihVD3NDfQO1ldbtW4un4bN/F8SRQE6XPe3i6jyplg11RQXAe+/m9F6MLGR2DIkuQeY3I/kVSGwhX25Gvt6MLG5GljcjxZhnZpbrnhsOWOwLp6Nc2qP8MjZ6V7LRm0cyDIZetpJNI8tBPIqvum7V7CmPfTLxhCaLlYt2gjqDR1rwTDhcp1KPl+aZfdqfFEB7IPnW8yMGQgpmT+T922PZfVrlIEnT9Mjg981uX29U3VpVkg8xNIqKmpsaTA/Q45jUgNGaObrCemNMyhHn8o0p87iaxwahH6yIDfEmxtrMw170fTFKYZvrGh6rxzCL/PNbGZ7Yyoe6n+hUqi2dmv9kh9X85wWbttGqxvUFNM+fPUXtG2ruLQ7nlwX1t/ZbyRJOwX1Jd/vnbnfPHzq/k9332S3Gh1t8drV9jF2L3rUdc5/Ix/EYoyiYhcj+ToiBCbFX0k0jz8Sejs/sJiw6C57MXTRzmJ11OODwDJfPcPgiHJ/hyGnc0VYsglvZgm5hyyddtus88UcuZZ+/J99vVm/UozMOAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/molecules/CreateNewScenarioForm/CreateNewScenarioForm.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/molecules/CreateNewScenarioForm/CreateNewScenarioForm.css.ts.vanilla.css\",\"source\":\"Ll8yZXRndXYwIHsKICBtYXgtd2lkdGg6IDgwMHB4OwogIHotaW5kZXg6IDU7CiAgcGFkZGluZy1sZWZ0OiA0NHB4OwogIHBhZGRpbmctcmlnaHQ6IDQ0cHg7Cn0KLl8yZXRndXYxIHsKICBoZWlnaHQ6IDM2cHg7Cn0KLl8yZXRndXYyIHsKICBmb250LXNpemU6IDQ1cHg7Cn0KLl8yZXRndXYzIHsKICBkaXNwbGF5OiBmbGV4OwogIG1hcmdpbi10b3A6IDQ1cHg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjsKICBtYXJnaW4tYm90dG9tOiA2MnB4Owp9Ci5fMmV0Z3V2NCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGdhcDogMjVweDsKfQouXzJldGd1djUgewogIGZvbnQtc2l6ZTogMTZweDsKICB3aWR0aDogMjM1cHg7CiAgaGVpZ2h0OiA1NHB4OwogIGJvcmRlci1yYWRpdXM6IDI1cHg7CiAgY29sb3I6ICNGRkY7Cn0KLl8yZXRndXY2IHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiByb3c7Cn0KLl8yZXRndXY3IHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAgbWFyZ2luLWxlZnQ6IGF1dG87Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var CreateNewScenarioFormContainer = '_2etguv0 _1r644tv0';
export var createScenarioButton = '_2etguv5 _123dvtq6';
export var createScenarioButtonContainer = '_2etguv3';
export var formAccentBar = '_2etguv1';
export var formInputsContainer = '_2etguv4';
export var formTitleText = '_2etguv2 _123dvtq4 _123dvtq3';
export var formTitleTextAndProgressIndicatorContainer = '_2etguv6';
export var stepProgressIndicatorContainer = '_2etguv7';