import React, { FC } from "react";
import { LineChartTile } from "../../molecules/LineChartTile/LineChartTile";

interface IHistoricalInterestRateChartTile {
  title: string;
  legendText: string;
  data: any;
}

const HistoricalInterestRateChartTile: FC<IHistoricalInterestRateChartTile> = ({
  title,
  legendText,
  data,
}) => {
  return (
    <LineChartTile
      title={title}
      legendText={legendText}
      data={data}
      dataValueType={"PERCENTAGE"}
    />
  );
};

export default HistoricalInterestRateChartTile;
