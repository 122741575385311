export const totalPaymentBarChartMock = [{
  dataLabel: 'Amount of dollars',
  dataBackgroundColor: "#38BDF8",
  dataValues: [
      { label: ["Scenario 1"], value: 1073.64 },
      { label: ["Scenario 2"], value: 5000 },
      { label: ["Scenario 3"], value: 4574 },
      { label: ["Scenario 4"], value: 2937 },],
  stack: "Stack 0",
},
{
  dataLabel: 'Amount of dollars',
dataBackgroundColor: "#6366F1",
dataValues: [
  { label: ["Scenario 1"], value: 2000 },
  { label: ["Scenario 2"], value: 4500 },
  { label: ["Scenario 3"], value: 3000 },
  { label: ["Scenario 4"], value: 4789 },],
stack: "Stack 1",
}
]