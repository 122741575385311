import { Line } from "react-chartjs-2";
import { lineChartData } from "./constants";
import {
  historicalInterestRateChartContainer,
  historicalInterestRateTitleContainer,
  graphHeaderContainer,
  currentValueHeadlineText,
  badgeContainer,
  graphCustomLegendContainer,
} from "./LineChartTile.css";
import { lineChartOptions } from "./constants";
import { FC } from "react";
import { ILineChartTileProps } from "./types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import ChartLegendDonutIcon from "../../../assets/chart-legend-donut-purple.svg";
import { printableNumber } from "../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const LineChartTile: FC<ILineChartTileProps> = ({
  title,
  legendText,
  data,
  dataValueType,
}) => {
  const diffBetweenLatestRateAndPreviousRate = (
    +data[data.length - 2].value - +data[data.length - 3].value
  ).toFixed(dataValueType === "DOLLARS" ? 0 : 2);

  const mostCurrentValue = Number.parseFloat(data[data.length - 2].value);

  return (
    <div className={historicalInterestRateChartContainer}>
      <div className={historicalInterestRateTitleContainer}>
        <span>{title}</span>
      </div>
      <div className={graphHeaderContainer}>
        <div className={currentValueHeadlineText}>
          <span>
            {dataValueType === "DOLLARS"
              ? mostCurrentValue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : (mostCurrentValue / 100).toLocaleString("en-US", {
                  style: "percent",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </span>
        </div>
        <div className={badgeContainer}>
          <span>{printableNumber(diffBetweenLatestRateAndPreviousRate)}</span>
        </div>
        <div className={graphCustomLegendContainer}>
          <img src={ChartLegendDonutIcon} alt=""></img>
          <span>{legendText}</span>
        </div>
      </div>
      <div style={{ position: "relative", height: "240px" }}>
        <Line
          data={lineChartData(data)}
          options={lineChartOptions(dataValueType)}
        />
      </div>
    </div>
  );
};
