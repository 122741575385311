import React, { FC, useRef, useState } from "react";
import { IBarChartDropownProps } from "./types";
import {
  formDropdownInputContainer,
  formDropdownInput,
  formDropdownInputDropdownList,
  formDropdownInputOption,
  formDropdownInputSelectedOption,
  dropdownStyling,
} from "./BarChartDropown.css";
import { useEvent } from "react-use";

export const BarChartDropown: FC<IBarChartDropownProps> = ({
  initialPlaceholderValue,
  dropdownOptions,
  parentCallback,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState(initialPlaceholderValue);
  const [showDropdown, setShowDropDown] = useState(false);

  const dropdownRef = useRef(null);

  const onClickOutsideDropdownHandler = (e: Event) => {
    // @ts-ignore
    if (!dropdownRef?.current?.contains(e.target) && showDropdown) {
      setShowDropDown(false);
    }
  };

  useEvent("click", onClickOutsideDropdownHandler, window);

  const handleDropdownOptionClick = (option: string | React.ReactNode) => {
    setSelectedOption(option);
    setShowDropDown(false);
    parentCallback(option);
  };

  return (
    <div className={formDropdownInputContainer} ref={dropdownRef}>
      <div
        className={formDropdownInput}
        id="formDropdownInput"
        onClick={() => {
          setShowDropDown(!showDropdown);
        }}
      >
        <span className={formDropdownInputSelectedOption}>
          <b>{selectedOption}</b>
        </span>
      </div>
      {showDropdown ? (
        <ul className={formDropdownInputDropdownList}>
          {dropdownOptions.map((option) => {
            return (
              <li
                className={formDropdownInputOption}
                onClick={() => handleDropdownOptionClick(option)}
              >
                <div className={dropdownStyling}>
                  <b>{option}</b>
                </div>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
