import { ChartOptions } from "chart.js";

export const lineChartData = (data: Array<{ date: string; value: string }>) => {
  const filteredObservations = data.filter(
    (observation) => observation.value !== "."
  );
  return {
    labels: filteredObservations.map((observation) => observation.date),
    datasets: [
      {
        label: "30yr Fixed",
        data: filteredObservations.map((observation) => observation.value),
        fill: true,
        borderColor: "#6366F1",
        backgroundColor: "rgba(59, 130, 246, 0.08)",
      },
    ],
  };
};

export const lineChartOptions = (
  dataValueType: "DOLLARS" | "PERCENTAGE"
): ChartOptions<"line"> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        align: "end",
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          font: {
            size: 12,
            family: "Inter",
          },
        },
      },
      tooltip: {
        backgroundColor: "#F1F5F9",
        titleColor: "#1E293B",
        bodyColor: "#1E293B",
        cornerRadius: 0,
        borderColor: "#1E293B",
        borderWidth: 2,
        bodyFont: {
          size: 16,
          family: "Inter",
        },
        titleFont: {
          size: 20,
          family: "Inter",
        },
        displayColors: false,
        padding: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
        backgroundColor: "#6366F1",
        hitRadius: 6,
        hoverRadius: 0,
      },
      line: {
        borderWidth: 6,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            family: "Inter",
          },
          padding: 12,
          //@ts-ignore
          callback: function (value: any, idx: any, ticks: any) {
            //@ts-ignore
            const date = new Date(this?.getLabelForValue(value));
            if (idx === 0) return date.getUTCFullYear();
            if (idx === ticks.length - 1) return date.getUTCFullYear();
            return null;
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 14,
            family: "Inter",
          },
          padding: 12,
          //@ts-ignore
          callback: function (value: string, idx: number, ticks: any) {
            if (idx % 2 === 0) {
              const valAsNumber = Number.parseFloat(value);
              if (dataValueType === "DOLLARS") {
                if (valAsNumber >= 1000000) return `$${valAsNumber / 1000000}M`;
                if (valAsNumber >= 1000) return `$${valAsNumber / 1000}K`;
                return valAsNumber.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                });
              } else if (dataValueType === "PERCENTAGE") {
                return (valAsNumber / 100).toLocaleString("en-US", {
                  style: "percent",
                });
              }
            }
            return null;
          },
        },
      },
    },
  };
};
