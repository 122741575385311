import { FC, useState } from "react";
import { ISideBySideButtonProps } from "./types";
import {
  activeButtonStyle,
  buttonStyle,
  sideBySideButtonContainer,
} from "./SideBySideButton.css";

export const SideBySideButton: FC<ISideBySideButtonProps> = ({
  buttonBackgroundColor,
  buttonActiveColor,
  leftButtonTitle,
  rightButtonTitle,
  onLeftButtonClick,
  onRightButtonClick,
}) => {
  const [selectedLeftButton, setSelectedLeftButton] = useState(true);

  return (
    <div
      className={sideBySideButtonContainer}
      style={{ background: buttonBackgroundColor }}
    >
      <button
        className={`${buttonStyle} ${
          selectedLeftButton ? activeButtonStyle : null
        }`}
        style={{
          background: selectedLeftButton ? buttonActiveColor : "none",
        }}
        onClick={(event) => {
          setSelectedLeftButton(true);
          onLeftButtonClick(event.currentTarget.value);
        }}
        value={leftButtonTitle}
      >
        {leftButtonTitle}
      </button>
      <button
        className={`${buttonStyle}  ${
          !selectedLeftButton ? activeButtonStyle : null
        }`}
        style={{
          background: !selectedLeftButton ? buttonActiveColor : "none",
        }}
        onClick={(e) => {
          setSelectedLeftButton(false);
          onRightButtonClick(e.currentTarget.value);
        }}
        value={rightButtonTitle}
      >
        {rightButtonTitle}
      </button>
    </div>
  );
};
