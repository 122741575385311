import { IScenarioType } from "../../../types";

export const loanScenarioTypes: Array<IScenarioType> = [
  "PURCHASE",
  "REFINANCE",
  "CASHOUT_REFINANCE",
  "HELOC",
  "NEW_CONSTRUCTION",
  "RATE_BUYDOWN",
  "RENT_VS_BUY",
  "REVERSE",
  "VA",
];
