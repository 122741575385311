import { FC } from "react";
import { IBarChart } from "./types";
import { Bar } from "react-chartjs-2";
import type { ChartData, ChartOptions, Plugin } from "chart.js";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChart: FC<IBarChart> = ({ barChartDataValues, ...props }) => {
  const data: ChartData<"bar"> = {
    labels: barChartDataValues[0].dataValues.map(
      (dataValue) => dataValue.label
    ),
    datasets: barChartDataValues.map((barChartDataValue) => {
      return {
        label: barChartDataValue.dataLabel,
        data: barChartDataValue.dataValues.map((dataValue) => dataValue.value),
        backgroundColor: barChartDataValue.dataBackgroundColor,
        stack: barChartDataValue.stack,
      };
    }),
  };

  const options: ChartOptions<"bar"> = {
    layout: {
      padding: {
        bottom: -20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: true,
          padding: 10,
          color: "white",
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, values) {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        },
      },
    },
  };

  const plugins: Plugin<"bar">[] = [
    {
      id: "multi-line-text",
      afterDraw: (chart, args, opts) => {
        const { ctx } = chart;
        const labelItems: string[][] = chart.data.labels as string[][];

        var xAxis = chart.scales["x"];
        var yAxis = chart.scales["y"];

        // styling
        for (let i = 0; i < labelItems.length; i++) {
          let labelItem = labelItems[i];

          const xValue = xAxis?.getPixelForValue(i);

          const xWidth = xAxis?.width;

          var largeFont = (xWidth / (labelItems.length * 10)).toString();
          var small = (xWidth / (labelItems.length * 15)).toString();

          const fontStringMain = largeFont + "px Inter";
          const fontStringSubTitle = "bold " + small + "px Inter";

          let offset = 0;
          labelItem.forEach((el) => {
            let elTextMetrics = ctx.measureText(el);

            if (labelItem.indexOf(el) === 0) {
              ctx.font = fontStringMain;
              ctx.fillStyle = "#94A3B8";
            } else {
              ctx.font = fontStringSubTitle;
              ctx.fillStyle = "#546F94";
            }

            ctx.save();
            ctx.fillText(el, xValue - 20, yAxis?.bottom + 15 + offset);
            ctx.restore();
            offset +=
              elTextMetrics.fontBoundingBoxAscent +
              elTextMetrics.fontBoundingBoxDescent;
          });
        }
      },
    },
  ];

  return <Bar data={data} options={options} plugins={plugins} />;
};
