import { FC } from "react";
import { ITotalPaymentAmountTile } from "./types";
import { BarChart } from "../../molecules/BarChart/BarChart";
import { titleContainer, principalAndInterestCostContainer, principalCostItem, interestCostItem, legendValue, legendLabel, barChartContainer, totalPaymentAmountTileContainer } from "./TotalPaymentAmountTile.css";
import ChartLegendDonutIconBlue from "../../../assets/chart-legend-donut-blue.svg";
import ChartLegendDonutIconPurple from "../../../assets/chart-legend-donut-purple.svg";

export const TotalPaymentAmountTile: FC<ITotalPaymentAmountTile> = ({
  principalCost,
  interestCost,
  barChartDataValues,
  ...props
}) => {

  return (
    <div className={totalPaymentAmountTileContainer}>
      <div className={titleContainer}>
        Total Payment Amount
      </div>
      <div className={principalAndInterestCostContainer}>
        <div className={principalCostItem}>
          <img src={ChartLegendDonutIconPurple} alt=""></img>
          <span className={legendValue} >${principalCost.toLocaleString()}</span> <span className={legendLabel} >Principal</span>
        </div>
        <div className={interestCostItem}>
          <img src={ChartLegendDonutIconBlue} alt=""></img>
          <span className={legendValue} >${interestCost.toLocaleString()}</span> <span className={legendLabel} >Interest</span>
        </div>
      </div>
      <div className={barChartContainer}>
        <BarChart barChartDataValues={barChartDataValues} />
      </div>
    </div>
  );
}
