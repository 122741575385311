import { FC } from "react";
import { IBrokerInfoRightHandRailProps } from "./types";
import { BrokerMetric } from "../../../types";
import {
  brockerMessageToProspectHeadlineText,
  brokerCallButton,
  brokerCallButtonContainer,
  brokerCompanyInfoContainer,
  brokerCompanyLogoContainer,
  brokerCompanyLogoImg,
  brokerCompanyMailingAddressContainer,
  brokerCompanyMailingAddressTitleText,
  brokerCompanyNameText,
  brokerCompanyStreetAddressText,
  brokerCompanyWebsiteLink,
  brokerCompanyWebsiteLinkContainer,
  brokerEmailButton,
  brokerEmailButtonContainer,
  brokerEmailLink,
  brokerEmailTextContainer,
  brokerHighlightMetricContainer,
  brokerHighlightMetricTileText,
  brokerHighlightMetricValueText,
  brokerInfoRightHandRailContainer,
  brokerMessageToProspectBodyText,
  brokerMessageToProspectiveBorrowerTextContainer,
  brokerMessageToProspectiveBorrowerTextContainerHorizontal,
  brokerMetricContainer,
  brokerMetricText,
  brokerMetricsContainer,
  brokerNameText,
  brokerNameTextContainer,
  brokerPhoneNumberTextContainer,
  brokerTagLineContainer,
  brokerValueText,
  horizontalBrokerAvatarAndContactInfoSection,
  horizontalBrokerCompanyInfoAndCtaSection,
  horizontalBrokerInfoHeaderSection,
  horizontalBrokerInfoRailContainer,
  horizontalRuleOnRightHandRail,
  phoneNumberLink,
  rightHandRailHeaderText,
  rightHandRailHeaderTextContainer,
  tagLineIconImg,
} from "./BrokerInfoRightHandRail.css";
import VerifiedBlueCheckMarkSvg from "../../../assets/verified-check-mark.svg";
import { openInNewTab } from "../../../utils";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPhone } from "@fortawesome/free-solid-svg-icons";
import { VideoAsk } from "../VideoAsk/VideoPreviewEntryButton/VideoAsk";

export const BrokerInfoRightHandRail: FC<IBrokerInfoRightHandRailProps> = ({
  brokerCompanyWebsite,
  brokerProfilePhoto,
  brokerName,
  brokerTagLine,
  brokerPhoneNumber,
  brokerEmail,
  brokerMetrics,
  brokerMessageToProspectiveBorrower,
  brokerCompanyLogo,
  brokerCompanyName,
  brokerCompanyStreetAddress,
  borrowerName,
  orientation = "vertical",
  reportVideoSource,
  onBrokerVideoNarrationPlay,
  ...props
}) => {
  return orientation === "vertical" ? (
    <div className={brokerInfoRightHandRailContainer}>
      <div className={rightHandRailHeaderTextContainer}>
        <h5 className={rightHandRailHeaderText}>Broker Information</h5>
      </div>
      <div style={{ width: "130px", height: "130px", marginBottom: "15px" }}>
        <VideoAsk
          greetingText={"Hey!"}
          brokerVideoSourceString={reportVideoSource}
          brokerProfilePhoto={brokerProfilePhoto}
          onBrokerVideoNarrationPlay={onBrokerVideoNarrationPlay}
        />
      </div>
      <div className={brokerNameTextContainer}>
        <h6 className={brokerNameText}>{brokerName}</h6>
      </div>
      <div className={brokerTagLineContainer}>
        <img
          className={tagLineIconImg}
          src={VerifiedBlueCheckMarkSvg}
          alt=""
        ></img>
        <span>{brokerTagLine}</span>
      </div>
      <div className={brokerPhoneNumberTextContainer}>
        <span>Phone: </span>
        <a className={phoneNumberLink} href={`tel:+1${brokerPhoneNumber}`}>
          {brokerPhoneNumber}
        </a>
      </div>
      <div className={brokerEmailTextContainer}>
        <span>Email: </span>
        <a className={brokerEmailLink} href={`mailto:${brokerEmail}`}>
          {brokerEmail}
        </a>
      </div>
      <hr className={horizontalRuleOnRightHandRail}></hr>
      <div className={brokerHighlightMetricContainer}>
        <span className={brokerHighlightMetricTileText}>
          {brokerMetrics?.[0]?.metricTitle}
        </span>
        <span className={brokerHighlightMetricValueText}>
          ${brokerMetrics?.[0]?.value}
        </span>
      </div>
      <div className={brokerMetricsContainer}>
        {brokerMetrics
          .slice(1, brokerMetrics.length)
          .map((metric: BrokerMetric) => {
            return (
              <div className={brokerMetricContainer}>
                <span className={brokerMetricText}>{metric.metricTitle}</span>
                <span className={brokerValueText}>{metric.value}</span>
              </div>
            );
          })}
      </div>
      <hr
        style={{ marginBottom: "23px" }}
        className={horizontalRuleOnRightHandRail}
      ></hr>
      <div className={brokerEmailButtonContainer}>
        <button className={brokerEmailButton}>
          <FontAwesomeIcon icon={faPhone} style={{ color: "#ffffff" }} />
          <span>Email {brokerName}</span>
        </button>
      </div>
      <div className={brokerCallButtonContainer}>
        <button className={brokerCallButton}>
          <FontAwesomeIcon icon={faEnvelope} style={{ color: "#ffffff" }} />
          <span>Call {brokerName}</span>
        </button>
      </div>
      <div className={brokerMessageToProspectiveBorrowerTextContainer}>
        <p className={brockerMessageToProspectHeadlineText}>
          Hey {borrowerName}!
        </p>
        <p className={brokerMessageToProspectBodyText}>
          {brokerMessageToProspectiveBorrower}
        </p>
      </div>
      <hr className={horizontalRuleOnRightHandRail}></hr>
      <div className={brokerCompanyInfoContainer}>
        <div className={brokerCompanyLogoContainer}>
          <img
            className={brokerCompanyLogoImg}
            src={brokerCompanyLogo}
            alt=""
          ></img>
        </div>
        <div>
          <span className={brokerCompanyNameText}>{brokerCompanyName}</span>
        </div>
        <div className={brokerCompanyMailingAddressContainer}>
          <h6 className={brokerCompanyMailingAddressTitleText}>
            Mailing Address:
          </h6>
          <span className={brokerCompanyStreetAddressText}>
            {brokerCompanyStreetAddress}
          </span>
        </div>
        <div className={brokerCompanyWebsiteLinkContainer}>
          <button
            className={brokerCompanyWebsiteLink}
            onClick={() => openInNewTab(brokerCompanyWebsite)}
          >
            <span>Go to website</span>
            <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={horizontalBrokerInfoRailContainer}>
      <div className={horizontalBrokerInfoHeaderSection}>
        <h2 style={{ margin: 0, marginBottom: "12px" }}>Broker Information</h2>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <div>
          <div
            className={horizontalBrokerAvatarAndContactInfoSection}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div>
              <div
                style={{ width: "120px", height: "120px", marginRight: "15px" }}
              >
                <VideoAsk
                  greetingText={"Hey!"}
                  brokerVideoSourceString={reportVideoSource}
                  brokerProfilePhoto={brokerProfilePhoto}
                  onBrokerVideoNarrationPlay={() => {}}
                />
              </div>
            </div>
            <div>
              <div className={brokerNameTextContainer}>
                <h6 className={brokerNameText} style={{ fontSize: "16px" }}>
                  {brokerName}
                </h6>
              </div>
              <div className={brokerPhoneNumberTextContainer}>
                <span>Phone: </span>
                <a
                  className={phoneNumberLink}
                  href={`tel:+1${brokerPhoneNumber}`}
                >
                  {brokerPhoneNumber}
                </a>
              </div>
              <div
                className={brokerEmailTextContainer}
                style={{ marginBottom: 4 }}
              >
                <span>Email: </span>
                <a className={brokerEmailLink} href={`mailto:${brokerEmail}`}>
                  {brokerEmail}
                </a>
              </div>
              <div>
                <div
                  className={brokerCompanyWebsiteLinkContainer}
                  style={{ margin: 0 }}
                >
                  <button
                    className={brokerCompanyWebsiteLink}
                    style={{
                      width: "max-content",
                      height: "20px",
                      borderRadius: "20px",
                      fontSize: "12px",
                    }}
                    onClick={() => openInNewTab(brokerCompanyWebsite)}
                  >
                    <span>Website</span>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ color: "#ffffff" }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={horizontalBrokerCompanyInfoAndCtaSection}>
            <div
              className={brokerCompanyInfoContainer}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "7px",
              }}
            >
              <div
                className={brokerCompanyLogoContainer}
                style={{
                  minWidth: "120px",
                  minHeight: "78px",
                  maxWidth: "160px",
                  height: "max-content",
                }}
              >
                <img
                  className={brokerCompanyLogoImg}
                  style={{
                    width: "100%",
                    height: "80px",
                    border: "1px solid black",
                  }}
                  src={brokerCompanyLogo}
                  alt=""
                ></img>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span className={brokerCompanyNameText}>
                    {brokerCompanyName}
                  </span>
                </div>
                <div className={brokerCompanyMailingAddressContainer}>
                  <h6 className={brokerCompanyMailingAddressTitleText}>
                    Mailing Address:
                  </h6>
                  <span className={brokerCompanyStreetAddressText}>
                    {brokerCompanyStreetAddress}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={brokerMessageToProspectiveBorrowerTextContainerHorizontal}
          style={{
            margin: 0,
            marginLeft: "auto",
            marginRight: "auto",
            width: "auto",
          }}
        >
          <p className={brockerMessageToProspectHeadlineText}>
            Hey {borrowerName}!
          </p>
          <p className={brokerMessageToProspectBodyText}>
            {brokerMessageToProspectiveBorrower}
          </p>
        </div>
      </div>
    </div>
  );
};
