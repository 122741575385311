import { FC } from "react";
import { IBannerProps } from "./types";
import {
  banner,
  bannerBody,
  bannerTextContainer,
  bannerTitle,
  decorativeBannerImg,
  decorativeBannerImgContainer,
} from "./Banner.css";
import GreenDecorativeArrows from "../../../assets/three-decorative-banner-arrows-green.svg";
import PurpleDecorativeArrows from "../../../assets/three-decorative-banner-arrows-purple.svg";
import { morfiLightGreen, morfiLightPurple } from "../../../tokens.css";

const getDecorativeArrowThatMatchesBackgroundColor = (
  backgroundColor: string
) => {
  if (backgroundColor === morfiLightPurple) return PurpleDecorativeArrows;
  if (backgroundColor === morfiLightGreen) return GreenDecorativeArrows;
  return undefined;
};

export const Banner: FC<IBannerProps> = ({ title, body, backgroundColor }) => {
  return (
    <div className={banner} style={{ backgroundColor: backgroundColor }}>
      <div className={bannerTextContainer}>
        <h4 className={bannerTitle}>{title}</h4>
        <p
          className={bannerBody}
          dangerouslySetInnerHTML={{ __html: body }}
        ></p>
      </div>
      <div className={decorativeBannerImgContainer}>
        <img
          className={decorativeBannerImg}
          src={getDecorativeArrowThatMatchesBackgroundColor(backgroundColor)}
          alt=""
        ></img>
      </div>
    </div>
  );
};
