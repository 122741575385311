import { FC } from "react";
import { ICreateScenarioButtonTileProps } from "./types";
import {
  CreateScenarioButtonTileContainer,
  createScenarioButton,
  createScenarioButtonTileTitleText,
  createScenarioButtonTileTitleTextContainer,
  plusIconContainer,
} from "./CreateScenarioButtonTile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import decorativeArrow from "../../../assets/right-arrow-decorative-purple.svg";
import { scenarioTypeContainer } from "../../organisms/ScenarioTile/ScenarioTile.css";

export const CreateScenarioButtonTile: FC<ICreateScenarioButtonTileProps> = ({
  onCreateScenarioButtonClick,
}) => {
  return (
    <div className={CreateScenarioButtonTileContainer}>
      <button
        className={createScenarioButton}
        onClick={(e) => onCreateScenarioButtonClick(e)}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div>
            <img src={decorativeArrow} alt="" />
          </div>
        </div>
        <div className={createScenarioButtonTileTitleTextContainer}>
          <h2 className={createScenarioButtonTileTitleText}>Create Scenario</h2>
          <h5
            className={scenarioTypeContainer}
            style={{ textAlign: "left", fontWeight: 700 }}
          >
            {"NEW SCENARIO"}
          </h5>
        </div>
        <div className={plusIconContainer}>
          <FontAwesomeIcon
            icon={faCirclePlus}
            style={{ color: "#4249d7", height: "100%" }}
          />
        </div>
      </button>
    </div>
  );
};
