import React, { FC } from "react";
import { IFormSideBySideButtonInputProps } from "./types";
import {
  FormSideBySideButtonInputContainer,
  buttonStyle,
  lvlThreeHeadingText,
  sideBySideButtonContainer,
} from "./FormSideBySideButtonInput.css";

export const FormSideBySideButtonInput: FC<IFormSideBySideButtonInputProps> = ({
  label,
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick,
  primaryColor,
}) => {
  return (
    <div className={FormSideBySideButtonInputContainer}>
      <div>
        <h3 className={lvlThreeHeadingText}>{label}</h3>
      </div>
      <div className={sideBySideButtonContainer}>
        <button
          className={buttonStyle}
          style={{
            background: primaryColor,
            color: "#FFFFFF",
            border: `1px solid ${primaryColor}`,
          }}
        >
          {leftButtonText}
        </button>
        <button
          className={buttonStyle}
          style={{
            border: `1px solid ${primaryColor}`,
          }}
        >
          {rightButtonText}
        </button>
      </div>
    </div>
  );
};
