import { FC } from "react";
import { IFormTextInputProps } from "./types";
import {
  textInputContainer,
  textInput,
  inputLabel,
  inputContainer,
  unitSelectorButtonContainer,
} from "./FormTextInput.css";
import { morfiDarkPurple } from "../../../tokens.css";
import { ErrorMessage, Field } from "formik";

export const FormTextInput: FC<IFormTextInputProps> = ({
  label,
  required,
  inputBorderColor = morfiDarkPurple,
  unitSelectorButton,
  onInputChange,
  formikField,
  name,
  ...props
}) => {
  return (
    <div className={textInputContainer}>
      <label htmlFor="textInputlabel" className={inputLabel}>
        {label}
      </label>
      <ErrorMessage
        name={name}
        render={(msg) =>
          typeof msg == "object" ? (
            <div>{JSON.stringify(msg)}</div>
          ) : (
            <div>{msg}</div>
          )
        }
      />
      <div className={inputContainer}>
        <Field
          name="purchasePrice"
          placeholder={label}
          onChange={(e: any) => onInputChange(e?.target?.value)}
          className={textInput}
          style={{ border: `0.709436px solid ${inputBorderColor}` }}
        />
        {unitSelectorButton ? (
          <div className={unitSelectorButtonContainer}>
            {unitSelectorButton}
          </div>
        ) : null}
      </div>
    </div>
  );
};
