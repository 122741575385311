import { date, number, object, string } from "yup";

export const purchaseLoanShape = {
  homeValue: number().required("Home value is required."),
  downPayment: number().required("Down payment is required."),
  mortgageAmount: number().required("Mortgage amount is required."),
  loanTerm: number().required("Loan term is required."),
  interestRate: number().required("Interest rate is required."),
  privateMortgageInsurance: number().optional(),
  firstPaymentDate: date().required("First payment date is required."),
  extraPayment: number().optional(),
  extraPaymentFrequency: string().optional(),
};

//Step 2
export const purchaseLoanFormSchema = object().shape(purchaseLoanShape);
