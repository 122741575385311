import "src/tokens.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens.css.ts.vanilla.css\",\"source\":\"Ll8xMjNkdnRxMCB7CiAgZm9udC1mYW1pbHk6IEJpdHRlcjsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBjb2xvcjogIzRFNTE1NjsKfQouXzEyM2R2dHExIHsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fMTIzZHZ0cTIgewogIGZvbnQtZmFtaWx5OiBSYWxld2F5OwogIGZvbnQtc3R5bGU6IG5vcm1hbDsKICBmb250LXNpemU6IDI0cHg7Cn0KLl8xMjNkdnRxMyB7CiAgZm9udC1mYW1pbHk6IFBsYXlmYWlyIERpc3BsYXk7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtc2l6ZTogNTZweDsKICBsaW5lLWhlaWdodDogOTBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fMTIzZHZ0cTQgewogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl8xMjNkdnRxNSB7CiAgZm9udC1mYW1pbHk6IEludGVyOwogIGZvbnQtc2l6ZTogMjhweDsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBsaW5lLWhlaWdodDogbm9ybWFsOwp9Ci5fMTIzZHZ0cTYgewogIGZvbnQtZmFtaWx5OiBJbnRlcjsKICBmb250LXNpemU6IDI4cHg7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtd2VpZ2h0OiA2MDA7CiAgbGluZS1oZWlnaHQ6IG5vcm1hbDsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/organisms/VideoAsk/VideoTile/VideoTile.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/VideoAsk/VideoTile/VideoTile.css.ts.vanilla.css\",\"source\":\"LmNwdW52eTAgewogIHdpZHRoOiAxMDAlOwogIGhlaWdodDogMTAwJTsKICBib3JkZXItcmFkaXVzOiAxNHB4OwogIG92ZXJmbG93OiBoaWRkZW47CiAgYmFja2dyb3VuZDogcmdiYSgwLDAsMCwxKTsKfQouY3B1bnZ5MSB7CiAgd2lkdGg6IDEwMCU7CiAgaGVpZ2h0OiAxMDAlOwogIHBvc2l0aW9uOiByZWxhdGl2ZTsKfQouY3B1bnZ5MiB7CiAgd2lkdGg6IDEwMCU7CiAgaGVpZ2h0OiAxMnB4OwogIGJhY2tncm91bmQ6IHJnYmEoNTAsNTAsNTAsMC4yKTsKICBwb3NpdGlvbjogYWJzb2x1dGU7CiAgdG9wOiAwOwogIGxlZnQ6IDA7Cn0KLmNwdW52eTMgewogIGhlaWdodDogMTAwJTsKICBiYWNrZ3JvdW5kOiAjNDI0OUQ3OwogIG9wYWNpdHk6IDEwMCUgIWltcG9ydGFudDsKfQouY3B1bnZ5NCB7CiAgd2lkdGg6IDEwMCU7CiAgaGVpZ2h0OiAxMDAlOwogIG9iamVjdC1maXQ6IGNvdmVyOwogIHotaW5kZXg6IDE7Cn0KLmNwdW52eTUgewogIHBvc2l0aW9uOiBhYnNvbHV0ZTsKICB0b3A6IDMwcHg7CiAgbGVmdDogMDsKICB3aWR0aDogMTAwJTsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7CiAgZ2FwOiAxMHB4OwogIHotaW5kZXg6IDI7CiAgcGFkZGluZzogOHB4OwogIHBhZGRpbmctcmlnaHQ6IDE2cHg7CiAgYm94LXNpemluZzogYm9yZGVyLWJveDsKfQouY3B1bnZ5NiB7CiAgZm9udC1zaXplOiAxMnB4OwogIG1pbi1oZWlnaHQ6IDEwMCU7CiAgd2lkdGg6IDMycHg7CiAgaGVpZ2h0OiAyNnB4OwogIHBhZGRpbmc6IDA7CiAgbWFyZ2luOiAwOwogIG91dGxpbmU6IG5vbmU7CiAgYm9yZGVyOiBub25lOwogIGJvcmRlci1yYWRpdXM6IDRweDsKfQouY3B1bnZ5NyB7CiAgZm9udC1zaXplOiAxOHB4OwogIGNvbG9yOiAjRkZGOwp9Ci5jcHVudnk4IHsKICBiYWNrZ3JvdW5kOiBub25lOwogIGJvcmRlcjogbm9uZTsKICBvcGFjaXR5OiA2MCU7CiAgei1pbmRleDogMjsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var VideoTileContainer = 'cpunvy0';
export var controlsButton = 'cpunvy6 _123dvtq6';
export var controlsContainer = 'cpunvy5';
export var footerContainer = 'cpunvy9';
export var footerContentContainer = 'cpunvya';
export var playVideoButton = 'cpunvy8';
export var progressBar = 'cpunvy2';
export var progressIndicator = 'cpunvy3';
export var timeDisplayText = 'cpunvy7 _123dvtq6';
export var videoContainer = 'cpunvy1';
export var videoPlayer = 'cpunvy4';