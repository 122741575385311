import { FC, useState } from "react";
import { IMonthlyPaymentBarChart } from "./types";
import { BarChart } from "../../molecules/BarChart/BarChart";
import { monthlyPaymentBarChartTileContainer, monthlyPaymentBarChartTileDropdown, monthlyPaymentBarChartContainer } from "./MonthlyPaymentBarChart.css";
import { BarChartDropown } from "../../molecules/BarChartDropdown/BarChartDropown";

export const MonthlyPaymentBarChart: FC<IMonthlyPaymentBarChart> = ({
    barChartDataValues,
    initialPlaceHolder,
    ...props
}) => {
    const [selected, setSelected] = useState(initialPlaceHolder);

    const handleCallback = (childData: string) => {
        setSelected(childData);
    }

    return (
        <div className={monthlyPaymentBarChartTileContainer}>
            <div className={monthlyPaymentBarChartTileDropdown}>
                <BarChartDropown initialPlaceholderValue={selected}
                dropdownOptions={barChartDataValues.map((data) => data.title)}
                parentCallback={handleCallback} />
            </div>
            <div className={monthlyPaymentBarChartContainer}>
                <BarChart barChartDataValues={barChartDataValues.filter((data) => {
                    return data.title === selected
                })[0].barChartDataObject} />
            </div>
        </div>
    );
}