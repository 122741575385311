import { IUser } from "../types";

export const mockBorrower: IUser = {
  fname: "John",
  lname: "Smith",
  userId: "",
  email: "",
  age: 0,
  address: "",
  phone: "",
  createdAt: "",
  brokerageName: "",
  licenseNumber: "",
  yearsOfExperience: 0,
  brokerMetrics: [],
  roles: ["BORROWER"],
  company: {
    companyId: "",
    companyWebsite: "",
    companyLogo: "",
    companyName: "",
    companyStreetAddress: "",
  },
  tagline: "",
};

export const mockBroker: IUser = {
  fname: "Jane",
  lname: "Doe",
  userId: "",
  email: "",
  age: 0,
  address: "",
  phone: "",
  createdAt: "",
  brokerageName: "",
  licenseNumber: "",
  yearsOfExperience: 0,
  brokerMetrics: [],
  roles: ["BROKER"],
  company: {
    companyId: "",
    companyWebsite: "",
    companyLogo: "",
    companyName: "",
    companyStreetAddress: "",
  },
  tagline: "",
};
