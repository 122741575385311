import React, { FC } from "react";
import { IRefinanceScenarioFormInputSetProps } from "./types";
import { RefinanceScenarioFormInputSetContainer } from "./RefinanceScenarioFormInputSet.css";
import { SideBySideButton } from "../../../atoms/SideBySideButton/SideBySideButton";
import { FormSideBySideButtonInput } from "../../FormSideBySideButtonInput/FormSideBySideButtonInput";
import { FormTextInput } from "../../FormTextInput/FormTextInput";
import { Title } from "@mantine/core";

export const RefinanceScenarioFormInputSet: FC<
  IRefinanceScenarioFormInputSetProps
> = ({
  primaryColor,
  accentColor,

  // On input change
  onOriginalLoanAmountChange,
  onOriginalInterestRateChange,
  onOriginalLoanTermChange,
  onOriginalLoanStartDateChange,
  onCurrentLoanBalanceChange,
  onCashOutAmountChange,
  onRefinanceCostChange,
  onNewLoanAmountChange,
  onNewInterestRateChange,
  onNewLoanTermChange,
  onNewLoanStartDateChange,
  onHowBorrowerWillPayRefinanceCost,
}) => {
  return (
    <div className={RefinanceScenarioFormInputSetContainer}>
      <FormSideBySideButtonInput
        label={"What is most important to you?"}
        leftButtonText={"Low Monthly Payment"}
        rightButtonText={"Lower Interest Paid"}
        onLeftButtonClick={() => console.log("left click")}
        onRightButtonClick={() => console.log("right click")}
        primaryColor={primaryColor}
      />
      <Title order={4}>Current Loan</Title>
      <FormTextInput
        label={"Original Loan Amount"}
        required={false}
        onInputChange={onOriginalLoanAmountChange}
        name={""}
      />
      <FormTextInput
        label={"Original Interest Rate"}
        required={false}
        onInputChange={onOriginalInterestRateChange}
        name={""}
      />
      <FormTextInput
        label={"Original Loan Term"}
        required={false}
        unitSelectorButton={
          <SideBySideButton
            buttonBackgroundColor={accentColor}
            buttonActiveColor={primaryColor}
            leftButtonTitle={"Year"}
            rightButtonTitle={"Month"}
            onLeftButtonClick={() => {
              console.log("Borrower Selected");
            }}
            onRightButtonClick={() => {
              console.log("Seller Selected");
            }}
          />
        }
        onInputChange={onOriginalLoanTermChange}
        name={""}
      />
      <FormTextInput
        label={"Loan Start Date"}
        required={false}
        onInputChange={onOriginalLoanStartDateChange}
        name={""}
      />
      <Title order={4}>New Loan</Title>
      <FormTextInput
        label={"Current Loan Balance"}
        required={false}
        onInputChange={onCurrentLoanBalanceChange}
        name={""}
      />
      <FormTextInput
        label={"Cash Out Amount"}
        required={false}
        onInputChange={onCashOutAmountChange}
        name={""}
      />
      <FormTextInput
        label={"Refinance Costs"}
        required={false}
        onInputChange={onRefinanceCostChange}
        name={""}
      />
      <FormTextInput
        label={"New Loan Amount"}
        required={false}
        onInputChange={onNewLoanAmountChange}
        name={""}
      />
      <FormTextInput
        label={"New Rate"}
        required={false}
        onInputChange={onNewInterestRateChange}
        name={""}
      />
      <FormTextInput
        label={"New Loan Term"}
        required={false}
        unitSelectorButton={
          <SideBySideButton
            buttonBackgroundColor={accentColor}
            buttonActiveColor={primaryColor}
            leftButtonTitle={"Year"}
            rightButtonTitle={"Month"}
            onLeftButtonClick={() => {
              console.log("Borrower Selected");
            }}
            onRightButtonClick={() => {
              console.log("Seller Selected");
            }}
          />
        }
        onInputChange={onNewLoanTermChange}
        name={""}
      />
      <FormTextInput
        label={"New Loan Start Date"}
        required={false}
        onInputChange={onNewLoanStartDateChange}
        name={""}
      />
      <FormSideBySideButtonInput
        label={"Paying Refinance Costs"}
        leftButtonText={"Include In Loan"}
        rightButtonText={"Pay Out of Pocket"}
        onLeftButtonClick={onHowBorrowerWillPayRefinanceCost}
        onRightButtonClick={onHowBorrowerWillPayRefinanceCost}
        primaryColor={primaryColor}
      />
    </div>
  );
};
