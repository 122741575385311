import {
  ILendingScenario,
  Visualization,
  CustomerDetail,
  Fee,
  ITCAReport,
} from "../types";

const mockProperty = {
  id: "123456",
  address: "123 Main St",
  city: "Anytown",
  state: "CA",
  zipCode: "12345",
  price: 500000,
  bedrooms: 3,
  bathrooms: 2,
  squareFeet: 1800,
  lotSize: 0.25,
  yearBuilt: 2000,
  imageUrl: "https://example.com/property-image.jpg",
  description: "A beautiful property in a quiet neighborhood.",
  realEstateAgent: {
    id: "agent123",
    name: "Jane Smith",
    email: "jane@example.com",
    phoneNumber: "(123) 456-7890",
  },
};

// Define mock lending scenarios here
const mockScenarios: Array<ILendingScenario> = [
  {
    scenarioId: "54321",
    scenarioType: "PURCHASE",
    scenarioDescription: "Buying a new home",
    productId: "98765",
    productName: "DreamHome Mortgage",
    details: {
      loan: {
        type: ["CONVENTIONAL"],
        loanAmount: 500000,
        interestRate: 4.0,
        term: 30,
        homeValue: 800000,
        finalMortgageAmount: 600000,
        downPaymentAmount: 200000,
        firstPaymentDate: "12/01/2023",
        refi: {
          cashOutAmount: 0,
          refinanceCosts: {
            amount: null,
            paidBy: null,
          },
          howBorrowerWillPay: "CASH",
          original: {
            loanAmount: 400000,
            interestRate: 12,
            loanTerm: 20,
            firstPaymentDate: "",
            currentLoanBalance: 320000,
          },
          new: {
            loanAmount: 0,
            interestRate: 0,
            loanTerm: 0,
            firstPaymentDate: null,
          },
        },
        reserves: {
          hazardInsuranceReserves: {
            amount: null,
            paidBy: null,
          },
          taxReserves: {
            amount: null,
            paidBy: null,
          },
        },
        fees: {
          privateMortageInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          hazardInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          processingFee: {
            amount: null,
            paidBy: null,
          },
          appraisalFee: {
            amount: null,
            paidBy: null,
          },
          floodCertificationFee: {
            amount: null,
            paidBy: null,
          },
          taxServiceFee: {
            amount: null,
            paidBy: null,
          },
          pullCreditReportFee: {
            amount: null,
            paidBy: null,
          },
          lenderTitleInsurance: {
            amount: null,
            paidBy: null,
          },
          settlementFee: {
            amount: null,
            paidBy: null,
          },
          recordingFee: {
            amount: null,
            paidBy: null,
          },
          rateBuydownFee: {
            amount: null,
            paidBy: null,
          },
          veteransAffairFundingFee: {
            amount: null,
            paidBy: null,
          },
        },
      },
      earlyPayoffStrategies: [
        {
          strategyId: "23456",
          strategyType: "MONTHLY",
          extraPaymentAmount: 300,
          frequency: "WEEKLY",
        },
      ],
      property: {
        purchasePrice: 600000,
        zipcode: "67890",
        costs: {
          propertyTax: 4500,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "1010 Main Street",
        city: "Columbus",
        state: "OH",
      },
      rentVsBuy: {
        marginalTaxBracket: 0,
        buy: {
          annualCost: 0,
          sellingCost: 0,
          annualAppreciation: 0,
        },
        rent: {
          monthlyRent: 0,
          rentersInsurance: 0,
          rentAppreciation: 0,
        },
      },
    },
  },
  {
    scenarioId: "11111",
    scenarioType: "PURCHASE",
    scenarioDescription: "Exploring reverse mortgage options",
    productId: "22222",
    productName: "GoldenYears Reverse Mortgage",
    details: {
      loan: {
        type: ["CONFORMING"],
        loanAmount: 250000,
        interestRate: 5.5,
        term: 25,
        homeValue: 0,
        finalMortgageAmount: 0,
        downPaymentAmount: 0,
        firstPaymentDate: "",
        refi: {
          cashOutAmount: 0,
          refinanceCosts: {
            amount: null,
            paidBy: null,
          },
          howBorrowerWillPay: "CASH",
          original: {
            loanAmount: 900000,
            interestRate: 7,
            loanTerm: 25,
            firstPaymentDate: "",
            currentLoanBalance: 760000,
          },
          new: {
            loanAmount: 0,
            interestRate: 0,
            loanTerm: 0,
            firstPaymentDate: null,
          },
        },
        reserves: {
          hazardInsuranceReserves: {
            amount: null,
            paidBy: null,
          },
          taxReserves: {
            amount: null,
            paidBy: null,
          },
        },
        fees: {
          privateMortageInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          hazardInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          processingFee: {
            amount: null,
            paidBy: null,
          },
          appraisalFee: {
            amount: null,
            paidBy: null,
          },
          floodCertificationFee: {
            amount: null,
            paidBy: null,
          },
          taxServiceFee: {
            amount: null,
            paidBy: null,
          },
          pullCreditReportFee: {
            amount: null,
            paidBy: null,
          },
          lenderTitleInsurance: {
            amount: null,
            paidBy: null,
          },
          settlementFee: {
            amount: null,
            paidBy: null,
          },
          recordingFee: {
            amount: null,
            paidBy: null,
          },
          rateBuydownFee: {
            amount: null,
            paidBy: null,
          },
          veteransAffairFundingFee: {
            amount: null,
            paidBy: null,
          },
        },
      },
      earlyPayoffStrategies: [
        {
          strategyId: "12345",
          strategyType: "MONTHLY",
          extraPaymentAmount: 200,
          frequency: "MONTHLY",
        },
      ],
      property: {
        purchasePrice: 600000,
        zipcode: "67890",
        costs: {
          propertyTax: 4500,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "1111 Third Street",
        city: "Columbus",
        state: "OH",
      },
      rentVsBuy: {
        marginalTaxBracket: 0,
        buy: {
          annualCost: 0,
          sellingCost: 0,
          annualAppreciation: 0,
        },
        rent: {
          monthlyRent: 0,
          rentersInsurance: 0,
          rentAppreciation: 0,
        },
      },
    },
  },
  {
    scenarioId: "99999",
    scenarioType: "NEW_CONSTRUCTION",
    scenarioDescription: "Financing a new construction project",
    productId: "88888",
    productName: "BuildDream Mortgage",
    details: {
      loan: {
        type: ["CONSTRUCTION"],
        loanAmount: 700000,
        interestRate: 4.25,
        term: 30,
        homeValue: 0,
        finalMortgageAmount: 0,
        downPaymentAmount: 0,
        firstPaymentDate: "",
        refi: {
          cashOutAmount: 0,
          refinanceCosts: {
            amount: null,
            paidBy: null,
          },
          howBorrowerWillPay: "CASH",
          original: {
            loanAmount: 500000,
            interestRate: 8,
            loanTerm: 30,
            firstPaymentDate: "",
            currentLoanBalance: 450000,
          },
          new: {
            loanAmount: 0,
            interestRate: 0,
            loanTerm: 0,
            firstPaymentDate: null,
          },
        },
        reserves: {
          hazardInsuranceReserves: {
            amount: null,
            paidBy: null,
          },
          taxReserves: {
            amount: null,
            paidBy: null,
          },
        },
        fees: {
          privateMortageInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          hazardInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          processingFee: {
            amount: null,
            paidBy: null,
          },
          appraisalFee: {
            amount: null,
            paidBy: null,
          },
          floodCertificationFee: {
            amount: null,
            paidBy: null,
          },
          taxServiceFee: {
            amount: null,
            paidBy: null,
          },
          pullCreditReportFee: {
            amount: null,
            paidBy: null,
          },
          lenderTitleInsurance: {
            amount: null,
            paidBy: null,
          },
          settlementFee: {
            amount: null,
            paidBy: null,
          },
          recordingFee: {
            amount: null,
            paidBy: null,
          },
          rateBuydownFee: {
            amount: null,
            paidBy: null,
          },
          veteransAffairFundingFee: {
            amount: null,
            paidBy: null,
          },
        },
      },
      earlyPayoffStrategies: [
        {
          strategyId: "67890",
          strategyType: "LUMP SUM",
          extraPaymentAmount: 1000,
          frequency: "QUARTERLY",
        },
      ],
      property: {
        purchasePrice: 600000,
        zipcode: "67890",
        costs: {
          propertyTax: 4500,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "1212 Fourth Street",
        city: "Columbus",
        state: "OH",
      },
      rentVsBuy: {
        marginalTaxBracket: 0,
        buy: {
          annualCost: 0,
          sellingCost: 0,
          annualAppreciation: 0,
        },
        rent: {
          monthlyRent: 0,
          rentersInsurance: 0,
          rentAppreciation: 0,
        },
      },
    },
  },
  {
    scenarioId: "77777",
    scenarioType: "REFINANCE",
    scenarioDescription: "Refinancing to access equity",
    productId: "66666",
    productName: "EquityUnlock Refinance",
    details: {
      loan: {
        type: ["CONFORMING"],
        loanAmount: 400000,
        interestRate: 3.75,
        term: 20,
        homeValue: 0,
        finalMortgageAmount: 0,
        downPaymentAmount: 0,
        firstPaymentDate: "",
        refi: {
          cashOutAmount: 30000,
          refinanceCosts: {
            amount: 7300,
            paidBy: "SELLER",
          },
          howBorrowerWillPay: "CASH",
          original: {
            loanAmount: 800000,
            interestRate: 8,
            loanTerm: 30,
            firstPaymentDate: "12/31/2023",
            currentLoanBalance: 650000,
          },
          new: {
            loanAmount: 850000,
            interestRate: 6.5,
            loanTerm: 30,
            firstPaymentDate: new Date("1/31/2023"),
          },
        },
        reserves: {
          hazardInsuranceReserves: {
            amount: null,
            paidBy: null,
          },
          taxReserves: {
            amount: null,
            paidBy: null,
          },
        },
        fees: {
          privateMortageInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          hazardInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          processingFee: {
            amount: null,
            paidBy: null,
          },
          appraisalFee: {
            amount: null,
            paidBy: null,
          },
          floodCertificationFee: {
            amount: null,
            paidBy: null,
          },
          taxServiceFee: {
            amount: null,
            paidBy: null,
          },
          pullCreditReportFee: {
            amount: null,
            paidBy: null,
          },
          lenderTitleInsurance: {
            amount: null,
            paidBy: null,
          },
          settlementFee: {
            amount: null,
            paidBy: null,
          },
          recordingFee: {
            amount: null,
            paidBy: null,
          },
          rateBuydownFee: {
            amount: null,
            paidBy: null,
          },
          veteransAffairFundingFee: {
            amount: null,
            paidBy: null,
          },
        },
      },
      earlyPayoffStrategies: [
        {
          strategyId: "98765",
          strategyType: "MONTHLY",
          extraPaymentAmount: 150,
          frequency: "BIWEEKLY",
        },
      ],
      property: {
        purchasePrice: 600000,
        zipcode: "67890",
        costs: {
          propertyTax: 4500,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "1010 Second St",
        city: "Columbus",
        state: "OH",
      },
      rentVsBuy: {
        marginalTaxBracket: 0,
        buy: {
          annualCost: 0,
          sellingCost: 0,
          annualAppreciation: 0,
        },
        rent: {
          monthlyRent: 0,
          rentersInsurance: 0,
          rentAppreciation: 0,
        },
      },
    },
  },
  {
    scenarioId: "44444",
    scenarioType: "HELOC",
    scenarioDescription: "Conventional mortgage for a first-time homebuyer",
    productId: "33333",
    productName: "StarterHome Mortgage",
    details: {
      loan: {
        type: ["CONFORMING"],
        loanAmount: 200000,
        interestRate: 3.25,
        term: 15,
        homeValue: 0,
        finalMortgageAmount: 0,
        downPaymentAmount: 0,
        firstPaymentDate: "",
        refi: {
          cashOutAmount: 0,
          refinanceCosts: {
            amount: null,
            paidBy: null,
          },
          howBorrowerWillPay: "CASH",
          original: {
            loanAmount: 760000,
            interestRate: 8,
            loanTerm: 30,
            firstPaymentDate: "",
            currentLoanBalance: 700000,
          },
          new: {
            loanAmount: 0,
            interestRate: 0,
            loanTerm: 0,
            firstPaymentDate: null,
          },
        },
        reserves: {
          hazardInsuranceReserves: {
            amount: null,
            paidBy: null,
          },
          taxReserves: {
            amount: null,
            paidBy: null,
          },
        },
        fees: {
          privateMortageInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          hazardInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          processingFee: {
            amount: null,
            paidBy: null,
          },
          appraisalFee: {
            amount: null,
            paidBy: null,
          },
          floodCertificationFee: {
            amount: null,
            paidBy: null,
          },
          taxServiceFee: {
            amount: null,
            paidBy: null,
          },
          pullCreditReportFee: {
            amount: null,
            paidBy: null,
          },
          lenderTitleInsurance: {
            amount: null,
            paidBy: null,
          },
          settlementFee: {
            amount: null,
            paidBy: null,
          },
          recordingFee: {
            amount: null,
            paidBy: null,
          },
          rateBuydownFee: {
            amount: null,
            paidBy: null,
          },
          veteransAffairFundingFee: {
            amount: null,
            paidBy: null,
          },
        },
      },
      earlyPayoffStrategies: [
        {
          strategyId: "54321",
          strategyType: "LUMP SUM",
          extraPaymentAmount: 5000,
          frequency: "ONCE",
        },
      ],
      property: {
        purchasePrice: 600000,
        zipcode: "67890",
        costs: {
          propertyTax: 4500,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "1313",
        city: "Columbus",
        state: "OH",
      },
      rentVsBuy: {
        marginalTaxBracket: 0,
        buy: {
          annualCost: 0,
          sellingCost: 0,
          annualAppreciation: 0,
        },
        rent: {
          monthlyRent: 0,
          rentersInsurance: 0,
          rentAppreciation: 0,
        },
      },
    },
  },
  {
    scenarioId: "44444",
    scenarioType: "RENT_VS_BUY",
    scenarioDescription: "Rent Vs Buy",
    productId: "33333",
    productName: "StarterHome Mortgage",
    details: {
      loan: {
        type: ["CONFORMING"],
        loanAmount: 200000,
        interestRate: 3.25,
        term: 15,
        homeValue: 800000,
        finalMortgageAmount: 0,
        downPaymentAmount: 0,
        firstPaymentDate: "12/31/2023",
        refi: {
          cashOutAmount: 0,
          refinanceCosts: {
            amount: null,
            paidBy: null,
          },
          howBorrowerWillPay: "CASH",
          original: {
            loanAmount: 900000,
            interestRate: 8,
            loanTerm: 30,
            firstPaymentDate: "12/01/23",
            currentLoanBalance: 760000,
          },
          new: {
            loanAmount: 0,
            interestRate: 0,
            loanTerm: 0,
            firstPaymentDate: null,
          },
        },
        reserves: {
          hazardInsuranceReserves: {
            amount: null,
            paidBy: null,
          },
          taxReserves: {
            amount: null,
            paidBy: null,
          },
        },
        fees: {
          privateMortageInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          hazardInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          processingFee: {
            amount: null,
            paidBy: null,
          },
          appraisalFee: {
            amount: null,
            paidBy: null,
          },
          floodCertificationFee: {
            amount: null,
            paidBy: null,
          },
          taxServiceFee: {
            amount: null,
            paidBy: null,
          },
          pullCreditReportFee: {
            amount: null,
            paidBy: null,
          },
          lenderTitleInsurance: {
            amount: null,
            paidBy: null,
          },
          settlementFee: {
            amount: null,
            paidBy: null,
          },
          recordingFee: {
            amount: null,
            paidBy: null,
          },
          rateBuydownFee: {
            amount: null,
            paidBy: null,
          },
          veteransAffairFundingFee: {
            amount: null,
            paidBy: null,
          },
        },
      },
      earlyPayoffStrategies: [
        {
          strategyId: "54321",
          strategyType: "LUMP SUM",
          extraPaymentAmount: 5000,
          frequency: "ONCE",
        },
      ],
      property: {
        purchasePrice: 600000,
        zipcode: "67890",
        costs: {
          propertyTax: 4500,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "1010 Third Street",
        city: "Columbus",
        state: "OH",
      },
      rentVsBuy: {
        marginalTaxBracket: 34,
        buy: {
          annualCost: 9000,
          sellingCost: 8000,
          annualAppreciation: 10000,
        },
        rent: {
          monthlyRent: 4000,
          rentersInsurance: 200,
          rentAppreciation: 100,
        },
      },
    },
  },
  {
    scenarioId: "44444",
    scenarioType: "VA",
    scenarioDescription: "Rent Vs Buy",
    productId: "33333",
    productName: "StarterHome Mortgage",
    details: {
      loan: {
        type: ["CONFORMING"],
        loanAmount: 200000,
        interestRate: 3.25,
        term: 15,
        homeValue: 800000,
        finalMortgageAmount: 0,
        downPaymentAmount: 0,
        firstPaymentDate: "12/31/2023",
        refi: {
          cashOutAmount: 0,
          refinanceCosts: {
            amount: null,
            paidBy: null,
          },
          howBorrowerWillPay: "CASH",
          original: {
            loanAmount: 900000,
            interestRate: 8,
            loanTerm: 30,
            firstPaymentDate: "12/01/23",
            currentLoanBalance: 760000,
          },
          new: {
            loanAmount: 0,
            interestRate: 0,
            loanTerm: 0,
            firstPaymentDate: null,
          },
        },
        reserves: {
          hazardInsuranceReserves: {
            amount: null,
            paidBy: null,
          },
          taxReserves: {
            amount: null,
            paidBy: null,
          },
        },
        fees: {
          privateMortageInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          hazardInsurancePremium: {
            amount: null,
            paidBy: null,
          },
          processingFee: {
            amount: null,
            paidBy: null,
          },
          appraisalFee: {
            amount: null,
            paidBy: null,
          },
          floodCertificationFee: {
            amount: null,
            paidBy: null,
          },
          taxServiceFee: {
            amount: null,
            paidBy: null,
          },
          pullCreditReportFee: {
            amount: null,
            paidBy: null,
          },
          lenderTitleInsurance: {
            amount: null,
            paidBy: null,
          },
          settlementFee: {
            amount: null,
            paidBy: null,
          },
          recordingFee: {
            amount: null,
            paidBy: null,
          },
          rateBuydownFee: {
            amount: null,
            paidBy: null,
          },
          veteransAffairFundingFee: {
            amount: 8500,
            paidBy: "SELLER",
          },
        },
      },
      earlyPayoffStrategies: [
        {
          strategyId: "54321",
          strategyType: "LUMP SUM",
          extraPaymentAmount: 5000,
          frequency: "ONCE",
        },
      ],
      property: {
        purchasePrice: 600000,
        zipcode: "67890",
        costs: {
          propertyTax: 4500,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "1010 Third Street",
        city: "Columbus",
        state: "OH",
      },
      rentVsBuy: {
        marginalTaxBracket: 34,
        buy: {
          annualCost: 9000,
          sellingCost: 8000,
          annualAppreciation: 10000,
        },
        rent: {
          monthlyRent: 4000,
          rentersInsurance: 200,
          rentAppreciation: 100,
        },
      },
    },
  },
];

// Define mock visualizations here
const mockVisualizations: Array<Visualization> = [];

const mockCustomerDetails: CustomerDetail = {
  assets: [],
  liabilities: [],
  purchaseBudget: 800000,
  targetPurchasePrice: 600000,
  targetPurchaseZipCodes: [],
  targetProperties: [],
};

// Define mock fees here
const mockFees: Fee[] = [];

export const mockTCA: ITCAReport = {
  reportId: "report123",
  brokerIds: ["broker123", "broker456"],
  borrowerIds: ["borrower789", "borrower012"],
  targetProperty: mockProperty,
  scenarios: mockScenarios,
  videoNarrationUrl:
    "https://media.videoask.com/transcoded/57da17f0-3857-4ad5-bb90-d962b40e8a60/embed.mp4?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWRpYV9pZCI6IjU3ZGExN2YwLTM4NTctNGFkNS1iYjkwLWQ5NjJiNDBlOGE2MCIsImV4cCI6MTY5MzkwNzE3Mn0.X9YyvPrsV-2nOAjXS-UGkpgFLzVT6aNthx7XO_VddN_i95jp2Po1zgUJhCCGaNBloKuaXKodJMbXwhD50I2A2W7m1rDGW9k0Vw43h8BVGXiIYG2a2gDsOUtfqjn3EyLG3vH146IGOW2cYifamDz6iBT58DSyvPC7gb_BG6aTyt99vPxt-yKgLI0P0afudjpzzPz9kGmsk1bML2G1285HdFwt2QYBVdKE7F6CylEgr8EC76Qsz6EBkr7-NawnkDuTRdwIX4dkyaAZEV-HHF__RCojT8Ac-ChAOiRNTyP4IuEgolaPPjaXwh8I6pG8FrF36TXfik8UBt5Lsy65sk7mvVg1-aD29tdSpouhQ4r5_Sob-5fUroJVi-qBHDbxgssmpNErg_Y6XhkZitTizxI3PKNnIkxBvpYt-QADOsSVusMTWN6E6MkHP4cs7H2nGmxG3AKY-x-fxfZrJLCxlYeLLR7BYZPF0LkO6TNahS0_5hhHqHK3-ozBVPOtCuDR6bwLlhlO3XH34UUBm8nmPWDvjkKczqKh9nkdaRv3HFeZYPsp85h39OkkEEPhiFj0Euc-6Wlmrtow0aeh9ybtDLZ7tTfnn8O1ZvH72c9L8TrB4HpS_FSJuixtriPmQwfxGmTJT7LocI1lOhwq1TkyLBzAixBGn7SR1CEzYSQyiEEFzKU",
  visualizations: mockVisualizations,
  showTour: true,
  customerDetails: mockCustomerDetails,
  fees: mockFees,
  brokerMessageToBorrower: "",
};
