import React, { FC } from "react";
import { IPurchaseScenarioLoanFormInputSetProps } from "./types";
import { PurchaseScenarioLoanFormInputSetContainer } from "./PurchaseScenarioLoanFormInputSet.css";
import { morfiLightPurple, morfiDarkPurple } from "../../../../tokens.css";
import { SideBySideButton } from "../../../atoms/SideBySideButton/SideBySideButton";
import { FormTextInput } from "../../FormTextInput/FormTextInput";
import { Form, FormikProvider, useFormik } from "formik";
import { purchaseLoanFormSchema, purchaseLoanShape } from "./validators";

// Simple function to get the structure (object with properties set to undefined) of an existing object
export const structure = (o: Object) =>
  Object.fromEntries(Object.keys(o).map((k) => [k]));

const initialPurchaseFormValues: typeof purchaseLoanShape =
  structure(purchaseLoanShape);

export const PurchaseScenarioLoanFormInputSet: FC<
  IPurchaseScenarioLoanFormInputSetProps
> = ({
  onHomeValueChange,
  onDownPaymentChange,
  onMortgageAmountChange,
  onLoanTermChange,
  onInterestRateChange,
  onPrivateMortgageInsuranceChange,
  onFirstPaymentDateChange,
  onExtraPaymentChange,
}) => {
  const formik = useFormik({
    initialValues: initialPurchaseFormValues,
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validationSchema: purchaseLoanFormSchema,
  });

  return (
    <div className={PurchaseScenarioLoanFormInputSetContainer}>
      <FormikProvider value={formik}>
        <Form>
          <div>
            <h2>Loan Details</h2>
            <FormTextInput
              label={"Home Value"}
              required={false}
              onInputChange={onHomeValueChange}
              name={"homeValue"}
            />
            <FormTextInput
              label={"Down Payment"}
              required={false}
              unitSelectorButton={
                <SideBySideButton
                  buttonBackgroundColor={morfiLightPurple}
                  buttonActiveColor={morfiDarkPurple}
                  leftButtonTitle={"$"}
                  rightButtonTitle={"%"}
                  onLeftButtonClick={() => {
                    console.log("Borrower Selected");
                  }}
                  onRightButtonClick={() => {
                    console.log("Seller Selected");
                  }}
                />
              }
              onInputChange={onDownPaymentChange}
              name={"downPayment"}
            />
            <FormTextInput
              label={"Mortgage Amount"}
              required={false}
              onInputChange={onMortgageAmountChange}
              name={"mortgageAmount"}
            />
            <FormTextInput
              label={"Loan Term"}
              required={false}
              unitSelectorButton={
                <SideBySideButton
                  buttonBackgroundColor={morfiLightPurple}
                  buttonActiveColor={morfiDarkPurple}
                  leftButtonTitle={"Year"}
                  rightButtonTitle={"Month"}
                  onLeftButtonClick={() => {
                    console.log("Borrower Selected");
                  }}
                  onRightButtonClick={() => {
                    console.log("Seller Selected");
                  }}
                />
              }
              onInputChange={onLoanTermChange}
              name={"loanTerm"}
            />
            <FormTextInput
              label={"Interest Rate"}
              required={false}
              onInputChange={onInterestRateChange}
              name={"interestRate"}
            />
            <FormTextInput
              label={"First Payment Date"}
              required={false}
              onInputChange={onFirstPaymentDateChange}
              name={"firstPaymentDate"}
            />
            <h2>Early Payoff Strategy</h2>
            <FormTextInput
              label={"Extra Payment (monthly)"}
              required={false}
              onInputChange={onExtraPaymentChange}
              unitSelectorButton={
                <SideBySideButton
                  buttonBackgroundColor={morfiLightPurple}
                  buttonActiveColor={morfiDarkPurple}
                  leftButtonTitle={"Annually"}
                  rightButtonTitle={"Monthly"}
                  onLeftButtonClick={() => {
                    console.log("Borrower Selected");
                  }}
                  onRightButtonClick={() => {
                    console.log("Seller Selected");
                  }}
                />
              }
              name={"extraPayment"}
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};
