import { FC, useEffect } from "react";
import { IFeeDetailsFormInputSetProps } from "./types";
import { FeeDetailsFormInputSetContainer } from "./FeeDetailsFormInputSet.css";
import { h2FormHeaderText } from "../../../../tokens.css";
import { SideBySideButton } from "../../../atoms/SideBySideButton/SideBySideButton";
import { FormTextInput } from "../../FormTextInput/FormTextInput";
import { Form, FormikProvider, useFormik } from "formik";
import { feeDetailFormSchema, feeDetailFormSchemaShape } from "./validators";
import { structure } from "../PurchaseScenarioLoanFormInputSet/PurchaseScenarioLoanFormInputSet";

const initialFeeDetailFormValues = structure(feeDetailFormSchemaShape);

export const FeeDetailsFormInputSet: FC<IFeeDetailsFormInputSetProps> = ({
  // Color config
  primaryColor,
  accentColor,

  // Callbacks
  onHazardInsuranceReservesChange,
  onHazardInsurancePremiumChange,
  onTaxReservesChange,
  onProcessingFeeChange,
  onAppraisalFeeChange,
  onFloodCertificationFeeChange,
  onTaxServiceFeeChange,
  onCreditReportFeeChange,
  onLenderTitleInsuranceFeeChange,
  onSettlementFeeChange,
  onRecordingFeeChange,
  onRateBuydownFeeChange,
}) => {
  const inputs = [
    {
      title: "Hazard Insurance Reserves",
      name: "hazardInsuranceReserves",
      callback: onHazardInsuranceReservesChange,
    },
    {
      title: "Hazard Insurance Premium",
      name: "hazardInsurancePremium",
      callback: onHazardInsurancePremiumChange,
    },
    {
      title: "Taxes Reserves",
      name: "taxReserves",
      callback: onTaxReservesChange,
    },
    {
      title: "Processing Fee",
      name: "processingFee",
      callback: onProcessingFeeChange,
    },
    {
      title: "Appraisal Fee",
      name: "appraisalFee",
      callback: onAppraisalFeeChange,
    },
    {
      title: "Flood Certification Fee",
      name: "floodCertificationFee",
      callback: onFloodCertificationFeeChange,
    },
    {
      title: "Tax Service Fee",
      name: "taxServiceFee",
      callback: onTaxServiceFeeChange,
    },
    {
      title: "Credit Report",
      name: "creditReportFee",
      callback: onCreditReportFeeChange,
    },
    {
      title: "Lender's Title Insurance",
      name: "lenderTitleInsurance",
      callback: onLenderTitleInsuranceFeeChange,
    },
    {
      title: "Settlement Fee",
      name: "settlementFee",
      callback: onSettlementFeeChange,
    },
    {
      title: "Recording Fee",
      name: "recordingFee",
      callback: onRecordingFeeChange,
    },
    {
      title: "2-1 Temporary Buy Down Fee",
      name: "temporaryBuyDownFee",
      callback: onRateBuydownFeeChange,
    },
  ];

  useEffect(() => {
    console.log(initialFeeDetailFormValues);
  });
  const formik = useFormik({
    initialValues: initialFeeDetailFormValues,
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validationSchema: feeDetailFormSchema,
  });

  return (
    <div className={FeeDetailsFormInputSetContainer}>
      <FormikProvider value={formik}>
        <Form>
          <h2 className={h2FormHeaderText}>Fee Details</h2>
          {inputs.map((input) => {
            return (
              <FormTextInput
                label={input.title}
                name={input.name ?? ""}
                required={false}
                unitSelectorButton={
                  <SideBySideButton
                    buttonBackgroundColor={accentColor}
                    buttonActiveColor={primaryColor}
                    leftButtonTitle={"Borrower"}
                    rightButtonTitle={"Seller"}
                    onLeftButtonClick={input.callback}
                    onRightButtonClick={input.callback}
                  />
                }
                onInputChange={input.callback}
              />
            );
          })}
        </Form>
      </FormikProvider>
    </div>
  );
};
