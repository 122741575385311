import { FC } from "react";
import { LineChartTile } from "../../molecules/LineChartTile/LineChartTile";
import { IHistoricalHomePriceChartTileProps } from "./types";

const HistoricalHomePriceChartTile: FC<IHistoricalHomePriceChartTileProps> = ({
  title,
  legendText,
  data,
  zipCode,
}) => {
  return (
    <LineChartTile
      title={title}
      legendText={legendText}
      data={data}
      dataValueType={"DOLLARS"}
    />
  );
};

export default HistoricalHomePriceChartTile;
