import { FC } from "react";
import { IDonutChart } from "./types";
import { Doughnut } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import { Plugin } from "chart.js";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DonutChart: FC<IDonutChart> = ({
  dataLabel,
  dataBackgroundColors,
  dataValues,
  centerText,
  ...props
}) => {
  const data: ChartData<"doughnut"> = {
    labels: dataValues.map((dataValue) => dataValue.label),
    datasets: [
      {
        label: dataLabel,
        data: dataValues.map((dataValue) => dataValue.value),
        backgroundColor: dataBackgroundColors,
        hoverOffset: 4,
        borderRadius: 6,
        offset: 6,
      },
    ],
  };

  const plugins: Plugin<"doughnut">[] = [];

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return <Doughnut data={data} options={options} plugins={plugins} />;
};
