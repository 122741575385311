import { number, object, string } from "yup";

// const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
// // Decimal and commas optional

// Step 1
export const propertyFormSchema = object().shape({
  scenarioType: string().required("Scenario type is required."),
  purchasePrice: number().required("Purchase price is required."),
  streetAddress: string().required("Street address is required."),
  city: string().required("City is required."),
  state: string().required("State is required."),
  zip: string().required("Zip is required."),
  propertyTax: number().required("Property tax is required."),
  homeOwnersInsurance: number().optional(),
  homeOwnersAssociation: number().optional(),
  energyCosts: number().optional(),
  waterCosts: number().optional(),
});
