export const mortgageRatesUs30Yr = [
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1971-01-01",
    value: ".",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1971-07-01",
    value: "7.60",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1972-01-01",
    value: "7.35",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1972-07-01",
    value: "7.42",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1973-01-01",
    value: "7.55",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1973-07-01",
    value: "8.54",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1974-01-01",
    value: "8.67",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1974-07-01",
    value: "9.70",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1975-01-01",
    value: "9.02",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1975-07-01",
    value: "9.07",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1976-01-01",
    value: "8.83",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1976-07-01",
    value: "8.91",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1977-01-01",
    value: "8.75",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1977-07-01",
    value: "8.93",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1978-01-01",
    value: "9.34",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1978-07-01",
    value: "9.94",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1979-01-01",
    value: "10.59",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1979-07-01",
    value: "11.82",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1980-01-01",
    value: "14.05",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1980-07-01",
    value: "13.43",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1981-01-01",
    value: "15.68",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1981-07-01",
    value: "17.56",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1982-01-01",
    value: "17.08",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1982-07-01",
    value: "15.09",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1983-01-01",
    value: "12.89",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1983-07-01",
    value: "13.55",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1984-01-01",
    value: "13.68",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1984-07-01",
    value: "14.07",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1985-01-01",
    value: "12.92",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1985-07-01",
    value: "11.94",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1986-01-01",
    value: "10.42",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1986-07-01",
    value: "9.96",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1987-01-01",
    value: "9.73",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1987-07-01",
    value: "10.68",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1988-01-01",
    value: "10.22",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1988-07-01",
    value: "10.45",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1989-01-01",
    value: "10.73",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1989-07-01",
    value: "9.91",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1990-01-01",
    value: "10.23",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1990-07-01",
    value: "10.03",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1991-01-01",
    value: "9.51",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1991-07-01",
    value: "8.98",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1992-01-01",
    value: "8.68",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1992-07-01",
    value: "8.11",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1993-01-01",
    value: "7.58",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1993-07-01",
    value: "7.07",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1994-01-01",
    value: "7.88",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1994-07-01",
    value: "8.84",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1995-01-01",
    value: "8.35",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1995-07-01",
    value: "7.52",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1996-01-01",
    value: "7.69",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1996-07-01",
    value: "7.93",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1997-01-01",
    value: "7.86",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1997-07-01",
    value: "7.34",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1998-01-01",
    value: "7.07",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1998-07-01",
    value: "6.82",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1999-01-01",
    value: "7.04",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "1999-07-01",
    value: "7.81",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2000-01-01",
    value: "8.29",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2000-07-01",
    value: "7.82",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2001-01-01",
    value: "7.07",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2001-07-01",
    value: "6.87",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2002-01-01",
    value: "6.89",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2002-07-01",
    value: "6.18",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2003-01-01",
    value: "5.67",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2003-07-01",
    value: "5.97",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2004-01-01",
    value: "5.86",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2004-07-01",
    value: "5.82",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2005-01-01",
    value: "5.74",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2005-07-01",
    value: "6.00",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2006-01-01",
    value: "6.42",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2006-07-01",
    value: "6.40",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2007-01-01",
    value: "6.29",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2007-07-01",
    value: "6.39",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2008-01-01",
    value: "5.98",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2008-07-01",
    value: "6.07",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2009-01-01",
    value: "5.04",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2009-07-01",
    value: "5.04",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2010-01-01",
    value: "4.96",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2010-07-01",
    value: "4.44",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2011-01-01",
    value: "4.75",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2011-07-01",
    value: "4.15",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2012-01-01",
    value: "3.86",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2012-07-01",
    value: "3.46",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2013-01-01",
    value: "3.58",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2013-07-01",
    value: "4.37",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2014-01-01",
    value: "4.30",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2014-07-01",
    value: "4.05",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2015-01-01",
    value: "3.77",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2015-07-01",
    value: "3.93",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-01-01",
    value: "3.66",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2016-07-01",
    value: "3.64",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-01-01",
    value: "4.08",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2017-07-01",
    value: "3.90",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-01-01",
    value: "4.41",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2018-07-01",
    value: "4.68",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-01-01",
    value: "4.19",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2019-07-01",
    value: "3.68",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-01-01",
    value: "3.38",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2020-07-01",
    value: "2.85",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-01-01",
    value: "2.94",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2021-07-01",
    value: "2.97",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-01-01",
    value: "4.54",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2022-07-01",
    value: "6.14",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-01-01",
    value: "6.44",
  },
  {
    realtime_start: "2023-08-12",
    realtime_end: "2023-08-12",
    date: "2023-07-01",
    value: ".",
  },
];
