export const barChartDataValueMocks = [
    {
        title: "Monthly Payment",
        barChartDataObject: [{
            dataLabel: 'Amount of dollars',
            dataBackgroundColor: "#38BDF8",
            dataValues: [
                { label: ["Scenario 1", "Refinance"], value: 1073.64 },
                { label: ["Scenario 2", "Purchase"], value: 5000 },
                { label: ["Scenario 3", "Refinance"], value: 4574 },
                { label: ["Scenario 4", "Refinance"], value: 2937 },
                { label: ["Scenario 5", "Purchase"], value: 6876 },
                { label: ["Scenario 6", "Refinance"], value: 1234 }],
            stack: "Stack 0",
        }]
    },
    {
        title: "Insurance Costs",
        barChartDataObject: [{
            dataLabel: 'Amount of dollars',
            dataBackgroundColor: "#38BDF8",
            dataValues: [
                { label: ["Scenario 1", "Refinance"], value: 500.64 },
                { label: ["Scenario 2", "Purchase"], value: 340 },
                { label: ["Scenario 3", "Refinance"], value: 420 },
                { label: ["Scenario 4", "Refinance"], value: 698 },
                { label: ["Scenario 5", "Purchase"], value: 534 },
                { label: ["Scenario 6", "Refinance"], value: 623 }],
            stack: "Stack 0",
        }]
    },
    {
        title: "Homeowners",
        barChartDataObject: [{
            dataLabel: 'Amount of dollars',
            dataBackgroundColor: "#38BDF8",
            dataValues: [
                { label: ["Scenario 1", "Refinance"], value: 100 },
                { label: ["Scenario 2", "Purchase"], value: 200 },
                { label: ["Scenario 3", "Refinance"], value: 300 },
                { label: ["Scenario 4", "Refinance"], value: 250 },
                { label: ["Scenario 5", "Purchase"], value: 150 },
                { label: ["Scenario 6", "Refinance"], value: 350 }],
            stack: "Stack 0",
        }]
    }
]