import { ILendingScenario, IScenarioType } from "../../../types";
import { PurchaseScenarioLoanFormInputSet } from "../FormInputSets/PurchaseScenarioLoanFormInputSet/PurchaseScenarioLoanFormInputSet";
import { uniqueId } from "lodash";
import { RentVsBuyScenarioFormSet } from "../FormInputSets/RentVsBuyScenarioFormSet/RentVsBuyScenarioFormSet";
import { RateBuydownFormInputSet } from "../FormInputSets/RateBuydownFormInputSet/RateBuydownFormInputSet";
import { RefinanceScenarioFormInputSet } from "../FormInputSets/RefinanceScenarioFormInputSet/RefinanceScenarioFormInputSet";
import { VeteranAffairsScenarioFormInputSet } from "../FormInputSets/VeteranAffairsScenarioFormInputSet/VeteranAffairsScenarioFormInputSet";

export const createEmptyFeeObject = () => {
  return { amount: null, paidBy: null };
};

export const createEmptyLendingScenario = (
  scenarioType: IScenarioType
): ILendingScenario => {
  return {
    scenarioId: uniqueId("scenario_"),
    scenarioType: scenarioType,
    scenarioDescription: null,
    productId: null,
    productName: null,
    details: {
      loan: {
        homeValue: 0,
        finalMortgageAmount: 0,
        downPaymentAmount: 0,
        firstPaymentDate: "",
        type: [],
        loanAmount: null,
        interestRate: null,
        term: null,
        reserves: {
          hazardInsuranceReserves: createEmptyFeeObject(),
          taxReserves: createEmptyFeeObject(),
        },
        fees: {
          hazardInsurancePremium: createEmptyFeeObject(),
          processingFee: createEmptyFeeObject(),
          appraisalFee: createEmptyFeeObject(),
          floodCertificationFee: createEmptyFeeObject(),
          taxServiceFee: createEmptyFeeObject(),
          pullCreditReportFee: createEmptyFeeObject(),
          lenderTitleInsurance: createEmptyFeeObject(),
          settlementFee: createEmptyFeeObject(),
          recordingFee: createEmptyFeeObject(),
          rateBuydownFee: createEmptyFeeObject(),
          veteransAffairFundingFee: createEmptyFeeObject(),
          privateMortageInsurancePremium: createEmptyFeeObject(),
        },
        refi: {
          cashOutAmount: 0,
          refinanceCosts: createEmptyFeeObject(),
          original: {
            loanAmount: 760000,
            interestRate: 4,
            loanTerm: 25,
            firstPaymentDate: "",
            currentLoanBalance: 700000,
          },
          new: {
            loanAmount: 0,
            interestRate: 0,
            loanTerm: 0,
            firstPaymentDate: null,
          },
          howBorrowerWillPay: "CASH",
        },
      },
      earlyPayoffStrategies: [],
      property: {
        purchasePrice: null,
        zipcode: null,
        costs: {
          propertyTax: null,
          homeownersInsurance: null,
          hoaFees: null,
          utilityCosts: {
            electric: null,
            water: null,
            gas: null,
            phone: null,
            internet: null,
          },
        },
        streetAddress: "",
        city: "",
        state: "",
      },
      rentVsBuy: {
        marginalTaxBracket: 0,
        buy: {
          annualCost: 0,
          sellingCost: 0,
          annualAppreciation: 0,
        },
        rent: {
          monthlyRent: 0,
          rentersInsurance: 0,
          rentAppreciation: 0,
        },
      },
    },
  };
};
export const getFormInputSetByScenarioType = (
  type: IScenarioType,
  props: { primaryColor: string; accentColor: string; setTmpScenario: Function }
) => {
  if (type === "PURCHASE") {
    return (
      <PurchaseScenarioLoanFormInputSet
        primaryColor={props.primaryColor}
        accentColor={props.accentColor}
        onHomeValueChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onHomeValueChange(value, state)
          );
        }}
        onDownPaymentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onDownPaymentChange(value, state)
          );
        }}
        onMortgageAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onMortgageAmountChange(value, state)
          );
        }}
        onLoanTermChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onLoanTermChange(value, state)
          );
        }}
        onInterestRateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onInterestRateChange(value, state)
          );
        }}
        onPrivateMortgageInsuranceChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onPrivateMortgageInsuranceChange(value, state)
          );
        }}
        onFirstPaymentDateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onFirstPaymentDateChange(value, state)
          );
        }}
        onExtraPaymentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onExtraPaymentChange(value, state)
          );
        }}
      />
    );
  }
  if (type === "RENT_VS_BUY") {
    return (
      <RentVsBuyScenarioFormSet
        primaryColor={props.primaryColor}
        accentColor={props.accentColor}
        onHomePriceChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onPurchasePriceChange(value, state)
          );
        }}
        onDownPaymentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onDownPaymentChange(value, state)
          );
        }}
        onLoanAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onMortgageAmountChange(value, state)
          );
        }}
        onInterestRateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onInterestRateChange(value, state)
          );
        }}
        onLoanTermChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onLoanTermChange(value, state)
          );
        }}
        onPrivateMortgageInsuranceChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onPrivateMortgageInsuranceChange(value, state)
          );
        }}
        onFirstPaymentDateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onFirstPaymentDateChange(value, state)
          );
        }}
        onMarginalTaxBracketChange={(value: any) => {
          props.setTmpScenario(
            (state: ILendingScenario) => console.log("Marginal Tax Bracket")
            //onMarginalTaxBracketChange(value, state)
          );
        }}
        onAnnualCostsChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onAnnualCostsChange(value, state)
          );
        }}
        onSellingCostsChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onSellingCostsChange(value, state)
          );
        }}
        onAnnualAppreciationChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onAnnualAppreciationChange(value, state)
          );
        }}
        onMonthlyRentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onMonthlyRentChange(value, state)
          );
        }}
        onRentersInsuranceChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onRentersInsuranceChange(value, state)
          );
        }}
        onRentAppreciationChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onRentAppreciationChange(value, state)
          );
        }}
      />
    );
  }
  if (type === "RATE_BUYDOWN") {
    return (
      <RateBuydownFormInputSet
        onRateBuyDownPercentageChange={() => {}}
        onHomeValueChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onHomeValueChange(value, state)
          );
        }}
        onDownPaymentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onDownPaymentChange(value, state)
          );
        }}
        onMortgageAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onMortgageAmountChange(value, state)
          );
        }}
        onLoanTermChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onLoanTermChange(value, state)
          );
        }}
        onInterestRateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onInterestRateChange(value, state)
          );
        }}
        onFirstPaymentDateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onFirstPaymentDateChange(value, state)
          );
        }}
        onExtraPaymentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onExtraPaymentChange(value, state)
          );
        }}
        onRateBuyDownChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onRateBuydownFeeChange(value, state)
          );
        }}
        {...props}
      />
    );
  }
  if (type === "CASHOUT_REFINANCE" || type === "REFINANCE") {
    return (
      <RefinanceScenarioFormInputSet
        onOriginalLoanAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onOriginalLoanAmountChange(value, state)
          );
        }}
        onOriginalInterestRateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onOriginalInterestRateChange(value, state)
          );
        }}
        onOriginalLoanTermChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onOriginalLoanTermChange(value, state)
          );
        }}
        onOriginalLoanStartDateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onOriginalLoanStartDateChange(value, state)
          );
        }}
        onCurrentLoanBalanceChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onCurrentLoanBalanceChange(value, state)
          );
        }}
        onCashOutAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onCashOutAmountChange(value, state)
          );
        }}
        onRefinanceCostChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onRefinanceCostChange(value, state)
          );
        }}
        onNewLoanAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onNewLoanAmountChange(value, state)
          );
        }}
        onNewInterestRateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onNewInterestRateChange(value, state)
          );
        }}
        onNewLoanTermChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onNewLoanTermChange(value, state)
          );
        }}
        onNewLoanStartDateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onNewLoanStartDateChange(value, state)
          );
        }}
        onHowBorrowerWillPayRefinanceCost={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onHowBorrowerWillPAyRefinanceCost(value, state)
          );
        }}
        {...props}
      />
    );
  }
  if (type === "VA") {
    return (
      <VeteranAffairsScenarioFormInputSet
        onHomeValueChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onHomeValueChange(value, state)
          );
        }}
        onDownPaymentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onDownPaymentChange(value, state)
          );
        }}
        onMortgageAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onMortgageAmountChange(value, state)
          );
        }}
        onLoanTermChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onLoanTermChange(value, state)
          );
        }}
        onInterestRateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onInterestRateChange(value, state)
          );
        }}
        onVeteranAffairsFundingFeeChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onVeteranAffairsFundingFeeChange(value, state)
          );
        }}
        onFinalMortgageAmountChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onFinalMortgageAmountChange(value, state)
          );
        }}
        onFirstPaymentDateChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onFirstPaymentDateChange(value, state)
          );
        }}
        onExtraPaymentChange={(value: any) => {
          props.setTmpScenario((state: ILendingScenario) =>
            onExtraPaymentChange(value, state)
          );
        }}
        onPaymentFrequencyChange={() => {}}
        {...props}
      />
    );
  }
  if (type === "HELOC") {
    <div>HELOC</div>;
  }
  if (type === "NEW_CONSTRUCTION") {
    <div>NEW CONSTRUCTION</div>;
  }
  if (type === "REVERSE") {
    <div>REVERSE</div>;
  }
};

// Property
export const onPurchasePriceChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: { ...state.details.property, purchasePrice: value },
    },
  };
};
export const onStreetAddressChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: { ...state.details.property, streetAddress: value },
    },
  };
};
export const onCityChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: { ...state.details.property, city: value },
    },
  };
};
export const onStateChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: { ...state.details.property, state: value },
    },
  };
};
export const onZipCodeChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: { ...state.details.property, zipcode: value },
    },
  };
};
export const onPropertyTaxChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: {
        ...state.details.property,
        costs: { ...state.details.property.costs, propertyTax: value },
      },
    },
  };
};
export const onHomeownersInsuranceChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: {
        ...state.details.property,
        costs: { ...state.details.property.costs, homeownersInsurance: value },
      },
    },
  };
};
export const onHomeownersAssociationChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: {
        ...state.details.property,
        costs: { ...state.details.property.costs, hoaFees: value },
      },
    },
  };
};
export const onEnergyCostChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: {
        ...state.details.property,
        costs: { ...state.details.property.costs, electric: value },
      },
    },
  };
};
export const onWaterCostChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      property: {
        ...state.details.property,
        costs: { ...state.details.property.costs, water: value },
      },
    },
  };
};

// Loan
export const onHomeValueChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        homeValue: value,
      },
    },
  };
};
export const onDownPaymentChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        downPaymentAmount: value,
      },
    },
  };
};
export const onMortgageAmountChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        loanAmount: value,
      },
    },
  };
};
export const onLoanTermChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        term: value,
      },
    },
  };
};
export const onInterestRateChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        interestRate: value,
      },
    },
  };
};
export const onVeteranAffairsFundingFeeChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, veteranAffairsFundingFee: value },
      },
    },
  };
};
export const onFinalMortgageAmountChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        finalMortgageAmount: value,
      },
    },
  };
};
export const onFirstPaymentDateChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        firstPaymentDate: value,
      },
    },
  };
};

// Fees
export const onHazardInsuranceReservesChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        reserves: {
          ...state.details.loan.reserves,
          hazardInsuranceReserves: value,
        },
      },
    },
  };
};
export const onHazardInsurancePremiumChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, hazardInsurancePremium: value },
      },
    },
  };
};
export const onTaxReservesChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        reserves: {
          ...state.details.loan.reserves,
          taxReserves: value,
        },
      },
    },
  };
};
export const onProcessingFeeChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, processingFee: value },
      },
    },
  };
};
export const onAppraisalFeeChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, appraisalFee: value },
      },
    },
  };
};
export const onFloodCertificationFeeChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, floodCertificationFee: value },
      },
    },
  };
};
export const onTaxServiceFeeChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, taxServiceFee: value },
      },
    },
  };
};
export const onCreditReportFeeChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, pullCreditReportFee: value },
      },
    },
  };
};
export const onLenderTitleInsuranceFeeChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, lenderTitleInsurance: value },
      },
    },
  };
};
export const onSettlementFeeChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, settlementFee: value },
      },
    },
  };
};
export const onRecordingFeeChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, recordingFee: value },
      },
    },
  };
};
export const onRateBuydownFeeChange = (value: any, state: ILendingScenario) => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: { ...state.details.loan.fees, rateBuydownFee: value },
      },
    },
  };
};

export const onPrivateMortgageInsuranceChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        fees: {
          ...state.details.loan.fees,
          privateMortageInsurancePremium: value,
        },
      },
    },
  };
};

export const onExtraPaymentChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      earlyPayoffStrategies: value,
    },
  };
};

export const onRentAppreciationChange = (
  value: any,
  state: ILendingScenario
) => {
  return {
    ...state,
    details: {
      ...state.details,
      rentVsBuy: {
        ...state.details.rentVsBuy,
        rent: {
          ...state.details.rentVsBuy.rent,
          rentAppreciation: value,
        },
      },
    },
  };
};

export const onAnnualCostsChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      rentVsBuy: {
        ...state.details.rentVsBuy,
        buy: {
          ...state.details.rentVsBuy.buy,
          annualCost: value,
        },
      },
    },
  };
};
export const onSellingCostsChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      rentVsBuy: {
        ...state.details.rentVsBuy,
        buy: {
          ...state.details.rentVsBuy.buy,
          sellingCost: value,
        },
      },
    },
  };
};

export const onAnnualAppreciationChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      rentVsBuy: {
        ...state.details.rentVsBuy,
        buy: {
          ...state.details.rentVsBuy.buy,
          annualAppreciation: value,
        },
      },
    },
  };
};
export const onMonthlyRentChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      rentVsBuy: {
        ...state.details.rentVsBuy,
        rent: {
          ...state.details.rentVsBuy.rent,
          monthlyRent: value,
        },
      },
    },
  };
};
export const onRentersInsuranceChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      rentVsBuy: {
        ...state.details.rentVsBuy,
        rent: {
          ...state.details.rentVsBuy.rent,
          rentersInsurance: value,
        },
      },
    },
  };
};

export const onOriginalLoanAmountChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          original: {
            ...state.details.loan.refi.original,
            loanAmount: value,
          },
        },
      },
    },
  };
};

export const onOriginalInterestRateChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          original: {
            ...state.details.loan.refi.original,
            interestRate: value,
          },
        },
      },
    },
  };
};

export const onOriginalLoanTermChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          original: {
            ...state.details.loan.refi.original,
            loanTerm: value,
          },
        },
      },
    },
  };
};

export const onOriginalLoanStartDateChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          original: {
            ...state.details.loan.refi.original,
            firstPaymentDate: value,
          },
        },
      },
    },
  };
};

export const onCurrentLoanBalanceChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          original: {
            ...state.details.loan.refi.original,
            currentLoanBalance: value,
          },
        },
      },
    },
  };
};

export const onCashOutAmountChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          cashOutAmount: value,
        },
      },
    },
  };
};

export const onRefinanceCostChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          refinanceCosts: value,
        },
      },
    },
  };
};

export const onNewLoanAmountChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          new: {
            ...state.details.loan.refi.new,
            loanAmount: value,
          },
        },
      },
    },
  };
};

export const onNewInterestRateChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          new: {
            ...state.details.loan.refi.new,
            interestRate: value,
          },
        },
      },
    },
  };
};

export const onNewLoanTermChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          new: {
            ...state.details.loan.refi.new,
            loanTerm: value,
          },
        },
      },
    },
  };
};

export const onNewLoanStartDateChange = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          new: {
            ...state.details.loan.refi.new,
            firstPaymentDate: value,
          },
        },
      },
    },
  };
};

export const onHowBorrowerWillPAyRefinanceCost = (
  value: any,
  state: ILendingScenario
): ILendingScenario => {
  return {
    ...state,
    details: {
      ...state.details,
      loan: {
        ...state.details.loan,
        refi: {
          ...state.details.loan.refi,
          howBorrowerWillPay: value,
        },
      },
    },
  };
};
