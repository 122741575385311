import React, { FC, useState } from "react";
import { IAccordianItemProps } from "./types";
import {
  AccordianItemContainer,
  accordianChildrenContainer,
  accordianHeaderContainer,
  accordianTitleText,
  accordianTitleTextContainer,
  expandCollapseAccordianButton,
} from "./AccordianItem.css";

export const AccordianItem: FC<IAccordianItemProps> = ({
  title,
  primaryColor,
  accentColor,
  children,
  ...props
}) => {
  const [accordianOpen, setAccordianOpen] = useState(false);
  return (
    <div className={AccordianItemContainer} style={{ background: accentColor }}>
      <div className={accordianHeaderContainer}>
        <button
          className={expandCollapseAccordianButton}
          onClick={() => setAccordianOpen((state) => !state)}
        >
          {accordianOpen ? "-" : "+"}
        </button>
        <div className={accordianTitleTextContainer}>
          <span className={accordianTitleText} style={{ color: primaryColor }}>
            {title}
          </span>
        </div>
      </div>
      {accordianOpen && (
        <div className={accordianChildrenContainer}>{children}</div>
      )}
    </div>
  );
};
