import "src/tokens.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens.css.ts.vanilla.css\",\"source\":\"Ll8xMjNkdnRxMCB7CiAgZm9udC1mYW1pbHk6IEJpdHRlcjsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBjb2xvcjogIzRFNTE1NjsKfQouXzEyM2R2dHExIHsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fMTIzZHZ0cTIgewogIGZvbnQtZmFtaWx5OiBSYWxld2F5OwogIGZvbnQtc3R5bGU6IG5vcm1hbDsKICBmb250LXNpemU6IDI0cHg7Cn0KLl8xMjNkdnRxMyB7CiAgZm9udC1mYW1pbHk6IFBsYXlmYWlyIERpc3BsYXk7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtc2l6ZTogNTZweDsKICBsaW5lLWhlaWdodDogOTBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fMTIzZHZ0cTQgewogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl8xMjNkdnRxNSB7CiAgZm9udC1mYW1pbHk6IEludGVyOwogIGZvbnQtc2l6ZTogMjhweDsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBsaW5lLWhlaWdodDogbm9ybWFsOwp9Ci5fMTIzZHZ0cTYgewogIGZvbnQtZmFtaWx5OiBJbnRlcjsKICBmb250LXNpemU6IDI4cHg7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtd2VpZ2h0OiA2MDA7CiAgbGluZS1oZWlnaHQ6IG5vcm1hbDsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/molecules/ScenarioHighlightTileDetailSets/PurchaseScenarioHighlightTile/PurchaseScenarioHighlightTile.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/molecules/ScenarioHighlightTileDetailSets/PurchaseScenarioHighlightTile/PurchaseScenarioHighlightTile.css.ts.vanilla.css\",\"source\":\"Ll8xZDJ5d2tzMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogcm93OwogIGZsZXgtd3JhcDogd3JhcDsKICBnYXA6IDMwcHg7Cn0KLl8xZDJ5d2tzNCB7CiAgbWFyZ2luOiAwOwogIGZvbnQtc2l6ZTogMThweDsKICBsaW5lLWhlaWdodDogMzBweDsKfQouXzFkMnl3a3M1IHsKICBjb2xvcjogIzk0QTNCODsKICBtYXJnaW46IDA7CiAgZm9udC1zaXplOiA5cHg7CiAgbGluZS1oZWlnaHQ6IDE1cHg7CiAgd2lkdGg6IG1heC1jb250ZW50Owp9Ci5fMWQyeXdrczYgewogIG1hcmdpbjogMDsKICBjb2xvcjogIzFFMjkzQjsKICBmb250LXNpemU6IDIyLjc0N3B4OwogIGxpbmUtaGVpZ2h0OiAzMHB4Owp9\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var PurchaseScenarioHighlightTileContainer = '_1d2ywks0';
export var earlyPayoffStrategySection = '_1d2ywks8';
export var feeDetailsSection = '_1d2ywks3';
export var loanDetailsSection = '_1d2ywks2';
export var monthlyPaymentCostSection = '_1d2ywks7';
export var propertyDetailsSection = '_1d2ywks1';
export var scenarioHighlightTileDetailSectionH2Style = '_1d2ywks4 _123dvtq4 _123dvtq3';
export var scenarioHighlightTileDetailSectionText = '_1d2ywks6 _123dvtq6';
export var scenarioHighlightTileDetailSectionTextLabel = '_1d2ywks5 _123dvtq6';