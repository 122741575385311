import { FC } from "react";
import { IRefinanceScenarioHighlightTileProps } from "./types";
import { RefinanceScenarioHighlightTileContainer } from "./RefinanceScenarioHighlightTile.css";
import { IEarlyPayOffStrategy } from "../../../../types";
import { AccordianItem } from "../../AccordianItem/AccordianItem";
import {
  propertyDetailsSection,
  scenarioHighlightTileDetailSectionH2Style,
  scenarioHighlightTileDetailSectionTextLabel,
  scenarioHighlightTileDetailSectionText,
  earlyPayoffStrategySection,
} from "../PurchaseScenarioHighlightTile/PurchaseScenarioHighlightTile.css";

export const RefinanceScenarioHighlightTile: FC<
  IRefinanceScenarioHighlightTileProps
> = ({ lendingScenario, primaryColor, accentColor }) => {
  return (
    <div className={RefinanceScenarioHighlightTileContainer}>
      <div className={propertyDetailsSection}>
        <h2
          className={scenarioHighlightTileDetailSectionH2Style}
          style={{ color: primaryColor }}
        >
          Property
        </h2>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Property Address
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.property.streetAddress},{" "}
              {lendingScenario.details.property.city},{" "}
              {lendingScenario.details.property.state}{" "}
              {lendingScenario.details.property.zipcode}
            </p>
          </div>
        </div>
      </div>
      <div style={{}}>
        <h2
          className={scenarioHighlightTileDetailSectionH2Style}
          style={{ color: primaryColor }}
        >
          Current Loan
        </h2>
        <div style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Original Loan Amount
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.refi.original.loanAmount}
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Original Rate
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.loan.refi.original.interestRate}%
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Original Loan Term
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.loan.refi.original.loanTerm}yr
            </p>
          </div>
          <div style={{ width: "min-content", minWidth: "80px" }}>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              First Payment Date
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.loan.refi.original.firstPaymentDate}
            </p>
          </div>
        </div>
      </div>
      <div style={{}}>
        <h2
          className={scenarioHighlightTileDetailSectionH2Style}
          style={{ color: primaryColor }}
        >
          New Loan
        </h2>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Current Loan Balance
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.refi.original.currentLoanBalance}
            </p>
          </div>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              New Loan Amount
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.refi.new.loanAmount}
            </p>
          </div>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              New Loan Term
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.refi.new.loanAmount}
            </p>
          </div>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              Cash Out Amount
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.refi.new.loanAmount}
            </p>
          </div>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              First Payment Date
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.loan.refi.new.firstPaymentDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              New Rate
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              {lendingScenario.details.loan.refi.new.interestRate}%
            </p>
          </div>
          <div>
            <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
              {`Refinance Cost ${
                lendingScenario.details.loan.refi.refinanceCosts.paidBy ?? ""
              }`}
            </h6>
            <p className={scenarioHighlightTileDetailSectionText}>
              ${lendingScenario.details.loan.refi.refinanceCosts.amount}
            </p>
          </div>
        </div>
      </div>
      <div style={{}}>
        {lendingScenario.details.earlyPayoffStrategies && (
          <div className={earlyPayoffStrategySection}>
            <h2
              className={scenarioHighlightTileDetailSectionH2Style}
              style={{ color: primaryColor }}
            >
              Early Payoff Strategy
            </h2>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              {lendingScenario.details.earlyPayoffStrategies.map(
                (earlyPayoffStrategy: IEarlyPayOffStrategy) => {
                  return (
                    <>
                      {" "}
                      <div>
                        <h6
                          className={
                            scenarioHighlightTileDetailSectionTextLabel
                          }
                        >
                          Extra Payment Amount
                        </h6>
                        <p className={scenarioHighlightTileDetailSectionText}>
                          ${earlyPayoffStrategy.extraPaymentAmount}
                        </p>
                      </div>
                      <div>
                        <h6
                          className={
                            scenarioHighlightTileDetailSectionTextLabel
                          }
                        >
                          Frequency
                        </h6>
                        <p className={scenarioHighlightTileDetailSectionText}>
                          {earlyPayoffStrategy.frequency}
                        </p>
                      </div>
                      <div>
                        <h6
                          className={
                            scenarioHighlightTileDetailSectionTextLabel
                          }
                        >
                          Total Savings
                        </h6>
                        <p className={scenarioHighlightTileDetailSectionText}>
                          ${earlyPayoffStrategy.extraPaymentAmount}
                        </p>
                      </div>
                    </>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <AccordianItem
          title={"Taxes, Insurance, and Fees"}
          primaryColor={primaryColor}
          accentColor={accentColor}
          children={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Private Mortgage Insurance
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Hazard Insurance Premium
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Appraisal Fee
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Credit Report
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Recording Fee
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Rate Buy Down Fee (SELLER)
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Tax Reserves
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Flood Certification
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Title Insurance
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Hazard Insurance Reserves
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Processing Fee
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Tax Service
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
              <div style={{ width: "125px" }}>
                <h6 className={scenarioHighlightTileDetailSectionTextLabel}>
                  Settlement Fee
                </h6>
                <p className={scenarioHighlightTileDetailSectionText}>$200</p>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
