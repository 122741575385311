import React, { FC } from "react";
import { IRentVsBuyScenarioFormSetProps } from "./types";
import { RentVsBuyScenarioFormSetContainer } from "./RentVsBuyScenarioFormSet.css";
import { FormTextInput } from "../../FormTextInput/FormTextInput";
import { h2FormHeaderText } from "../../../../tokens.css";
import { SideBySideButton } from "../../../atoms/SideBySideButton/SideBySideButton";

export const RentVsBuyScenarioFormSet: FC<IRentVsBuyScenarioFormSetProps> = ({
  // Dynamic Colors
  primaryColor,
  accentColor,

  // On Input Change
  onHomePriceChange,
  onDownPaymentChange,
  onLoanAmountChange,
  onInterestRateChange,
  onLoanTermChange,
  onPrivateMortgageInsuranceChange,
  onFirstPaymentDateChange,
  onMarginalTaxBracketChange,
  onAnnualCostsChange,
  onSellingCostsChange,
  onAnnualAppreciationChange,
  onMonthlyRentChange,
  onRentersInsuranceChange,
  onRentAppreciationChange,
}) => {
  return (
    <div className={RentVsBuyScenarioFormSetContainer}>
      <div>
        <h2 className={h2FormHeaderText}>Mortgage Information</h2>
        <FormTextInput
          label={"Home Price"}
          required={false}
          onInputChange={onHomePriceChange}
          name={""}
        />
        <FormTextInput
          label={"Down Payment"}
          required={false}
          unitSelectorButton={
            <SideBySideButton
              buttonBackgroundColor={accentColor}
              buttonActiveColor={primaryColor}
              leftButtonTitle={"$"}
              rightButtonTitle={"%"}
              onLeftButtonClick={() => {
                console.log("Borrower Selected");
              }}
              onRightButtonClick={() => {
                console.log("Seller Selected");
              }}
            />
          }
          onInputChange={onDownPaymentChange}
          name={""}
        />
        <FormTextInput
          label={"Loan Amount"}
          required={false}
          onInputChange={onLoanAmountChange}
          name={""}
        />
        <FormTextInput
          label={"Interest Rate"}
          required={false}
          onInputChange={onInterestRateChange}
          name={""}
        />
        <FormTextInput
          label={"Loan Term"}
          required={false}
          unitSelectorButton={
            <SideBySideButton
              buttonBackgroundColor={accentColor}
              buttonActiveColor={primaryColor}
              leftButtonTitle={"Year"}
              rightButtonTitle={"Month"}
              onLeftButtonClick={() => {
                console.log("Borrower Selected");
              }}
              onRightButtonClick={() => {
                console.log("Seller Selected");
              }}
            />
          }
          onInputChange={onLoanTermChange}
          name={""}
        />
        <FormTextInput
          label={"PMI (yearly)"}
          required={false}
          unitSelectorButton={
            <SideBySideButton
              buttonBackgroundColor={accentColor}
              buttonActiveColor={primaryColor}
              leftButtonTitle={"$"}
              rightButtonTitle={"%"}
              onLeftButtonClick={() => {
                console.log("Borrower Selected");
              }}
              onRightButtonClick={() => {
                console.log("Seller Selected");
              }}
            />
          }
          onInputChange={onPrivateMortgageInsuranceChange}
          name={""}
        />
        <FormTextInput
          label={"First Payment Date"}
          required={false}
          onInputChange={onFirstPaymentDateChange}
          name={""}
        />
        <h2 className={h2FormHeaderText}>Buying Assumptions</h2>
        <FormTextInput
          label={"Marginal Tax Bracket"}
          required={false}
          onInputChange={onMarginalTaxBracketChange}
          name={""}
        />
        <FormTextInput
          label={"Annual Costs"}
          required={false}
          onInputChange={onAnnualCostsChange}
          name={""}
        />
        <FormTextInput
          label={"Selling Costs"}
          required={false}
          onInputChange={onSellingCostsChange}
          name={""}
        />
        <FormTextInput
          label={"Annual Appreciation"}
          required={false}
          onInputChange={onAnnualAppreciationChange}
          name={""}
        />
        <h2 className={h2FormHeaderText}>Renting Assumptions</h2>
        <FormTextInput
          label={"Monthly Rent"}
          required={false}
          onInputChange={onMonthlyRentChange}
          name={""}
        />
        <FormTextInput
          label={"Renter's Insurance"}
          required={false}
          onInputChange={onRentersInsuranceChange}
          name={""}
        />
        <FormTextInput
          label={"Rent Appreciation"}
          required={false}
          onInputChange={onRentAppreciationChange}
          name={""}
        />
      </div>
    </div>
  );
};
