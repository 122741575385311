import { FC, useState } from "react";
import "./App.css";
import { TotalCostAnalysisReportPage } from "./components/pages/TotalCostAnalysisReportPage/TotalCostAnalyticsReportPage";
import { mockTCA } from "./mock/mocks";
import { mockBorrower, mockBroker } from "./mock/user";
import { morfiDarkPurple, morfiLightPurple } from "./tokens.css";
import { CreateNewScenarioForm } from "./components/molecules/CreateNewScenarioForm/CreateNewScenarioForm";
import { ITCAReport } from "./types";
import { useDisclosure } from "@mantine/hooks";
import { Button, Dialog, Group, Modal, TextInput, Text } from "@mantine/core";
import { VideoTile } from "./components/organisms/VideoAsk/VideoTile/VideoTile";

const App: FC = () => {
  const [tcaReport] = useState<ITCAReport>({
    ...mockTCA,
    scenarios: [],
  });
  const [prospect] = useState(mockBorrower);
  const [broker] = useState(mockBroker);

  const [primaryColor] = useState(morfiDarkPurple);
  const [accentColor] = useState(morfiLightPurple);

  // Create Lending Scenario Form
  const [
    isCreateLendingScenarioFormVisible,
    createLendingScenarioFormHandlers,
  ] = useDisclosure(false);

  // Broker Video Form
  const [
    isBrokerVideoNarrationTileVisible,
    isBrokerVideoNarrationTileVisibleHandler,
  ] = useDisclosure(false);

  // OptIn-OptOut SMS Messaging
  const [isOptInOptOutSmsMessaging, isOptInOptOutSmsMessagingHandler] =
    useDisclosure(true);

  const onStartScenarioCreation = () => {
    createLendingScenarioFormHandlers.open();
  };

  const onBrokerVideoNarrationPlay = () => {
    isBrokerVideoNarrationTileVisibleHandler.toggle();
  };

  return (
    <div className="App">
      <Dialog
        opened={isOptInOptOutSmsMessaging}
        withCloseButton
        onClose={() => {
          isOptInOptOutSmsMessagingHandler.close();
        }}
        size="lg"
        radius="md"
      >
        <Text size="sm" mb="xs" fw={500}>
          Connect with your broker by SMS
        </Text>

        <Group align="flex-end">
          <TextInput
            placeholder="+1 (000) 000-0000"
            style={{ flex: 1 }}
            type="tel"
          />
          <Button onClick={() => {}}>Text Me</Button>
        </Group>
      </Dialog>
      <CreateNewScenarioForm
        title={""}
        scenarioType={"PURCHASE"}
        primaryColor={""}
        accentColor={""}
        steps={[]}
        onCreateScenario={() => {
          console.log("Create Scenario");
        }}
        isCreateLendingScenarioFormVisible={isCreateLendingScenarioFormVisible}
        createLendingScenarioFormHandlers={createLendingScenarioFormHandlers}
      />
      <Modal
        opened={isBrokerVideoNarrationTileVisible}
        onClose={() => {
          isBrokerVideoNarrationTileVisibleHandler.close();
        }}
        withCloseButton={false}
        radius={30}
      >
        <div style={{ height: "600px" }}>
          <VideoTile
            videoSrc={tcaReport.videoNarrationUrl}
            videoTrackSrc={""}
          />
        </div>
      </Modal>
      <TotalCostAnalysisReportPage
        primaryColor={primaryColor}
        accentColor={accentColor}
        usersName={`${prospect.fname} ${prospect.lname}`}
        tcaReport={tcaReport}
        onStartScenarioCreation={onStartScenarioCreation}
        activeScenarioId={""}
        brokerCompanyWebsite={"website"}
        brokerProfilePhoto={"profile picture"}
        brokerName={`${broker.fname} ${broker.lname}`}
        brokerTagLine={broker.tagline}
        brokerPhoneNumber={broker.phone}
        brokerEmail={broker.email}
        brokerMetrics={broker.brokerMetrics}
        brokerMessageToProspectiveBorrower={tcaReport.brokerMessageToBorrower}
        brokerCompanyLogo={broker.company.companyLogo}
        brokerCompanyName={broker.company.companyName}
        brokerCompanyStreetAddress={broker.company.companyStreetAddress}
        borrowerName={`${prospect.fname} ${prospect.lname}`}
        orientation={"horizontal"}
        reportVideoSource={tcaReport.videoNarrationUrl}
        onBrokerVideoNarrationPlay={onBrokerVideoNarrationPlay}
      />
    </div>
  );
};

export default App;
