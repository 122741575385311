import "src/tokens.css.ts.vanilla.css!=!../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens.css.ts.vanilla.css\",\"source\":\"Ll8xMjNkdnRxMCB7CiAgZm9udC1mYW1pbHk6IEJpdHRlcjsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBjb2xvcjogIzRFNTE1NjsKfQouXzEyM2R2dHExIHsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fMTIzZHZ0cTIgewogIGZvbnQtZmFtaWx5OiBSYWxld2F5OwogIGZvbnQtc3R5bGU6IG5vcm1hbDsKICBmb250LXNpemU6IDI0cHg7Cn0KLl8xMjNkdnRxMyB7CiAgZm9udC1mYW1pbHk6IFBsYXlmYWlyIERpc3BsYXk7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtc2l6ZTogNTZweDsKICBsaW5lLWhlaWdodDogOTBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fMTIzZHZ0cTQgewogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl8xMjNkdnRxNSB7CiAgZm9udC1mYW1pbHk6IEludGVyOwogIGZvbnQtc2l6ZTogMjhweDsKICBmb250LXN0eWxlOiBub3JtYWw7CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBsaW5lLWhlaWdodDogbm9ybWFsOwp9Ci5fMTIzZHZ0cTYgewogIGZvbnQtZmFtaWx5OiBJbnRlcjsKICBmb250LXNpemU6IDI4cHg7CiAgZm9udC1zdHlsZTogbm9ybWFsOwogIGZvbnQtd2VpZ2h0OiA2MDA7CiAgbGluZS1oZWlnaHQ6IG5vcm1hbDsKfQ==\"}!../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bitterTextHeavy = '_123dvtq1 _123dvtq0';
export var bitterTextNormal = '_123dvtq0';
export var h2FormHeaderText = '_123dvtq7';
export var interHeavy = '_123dvtq6';
export var interNormal = '_123dvtq5';
export var morfiDarkPurple = '#4249D7';
export var morfiLightGreen = '#C7FFDF';
export var morfiLightPurple = '#C7D2FE';
export var playfairDisplayHeavy = '_123dvtq4 _123dvtq3';
export var playfairDisplayNormal = '_123dvtq3';
export var ralewayNormal = '_123dvtq2';