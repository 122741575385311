import { FC, useEffect, useRef } from "react";
import { IVideoAskProps } from "./types";
import {
  VideoAskContainer,
  recordVideoIconButton,
  videoAskPreviewAvatarButton,
  videoAskPreviewAvatarButtonGreetingText,
  videoAskPreviewAvatarVideoPreview,
  videoAskPreviewAvatarVideoPreviewContainer,
} from "./VideoAsk.css";
import { morfiDarkPurple } from "../../../../tokens.css";
import { useReactMediaRecorder } from "react-media-recorder";

// const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
//   const videoRef = useRef<HTMLVideoElement>(null);

//   useEffect(() => {
//     if (videoRef.current && stream) {
//       videoRef.current.srcObject = stream;
//     }
//   }, [stream]);
//   if (!stream) {
//     return null;
//   }
//   return <video ref={videoRef} width={500} height={500} autoPlay controls />;
// };

export const VideoAsk: FC<IVideoAskProps> = ({
  greetingText,
  brokerVideoSourceString,
  brokerProfilePhoto,
  onBrokerVideoNarrationPlay,
}) => {
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({
      video: true,
    });

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (
      videoRef &&
      videoRef.current &&
      previewStream &&
      status === "recording"
    ) {
      videoRef.current.srcObject = previewStream;
    } else if (videoRef && videoRef.current) {
      videoRef.current.srcObject = null;
    }
  }, [videoRef, previewStream, status]);

  return (
    <div className={VideoAskContainer}>
      <button
        className={videoAskPreviewAvatarButton}
        aria-label={greetingText}
        style={{
          zIndex: "999999 !important",
        }}
        onClick={() => {
          if (status === "idle") {
            startRecording();
            onBrokerVideoNarrationPlay();
          } else if (status === "recording") {
            stopRecording();
          }
        }}
      >
        <div
          className={videoAskPreviewAvatarVideoPreviewContainer}
          style={{ outline: `5px solid ${morfiDarkPurple}` }}
        >
          <div
            className={videoAskPreviewAvatarButtonGreetingText}
            style={{
              fontFamily:
                'Apercu Pro", apercu-pro, Arial, "Helvetica Neue", Helvetica, sans-serif !important;',
            }}
          >
            Hey!
          </div>
          {brokerVideoSourceString || mediaBlobUrl || previewStream ? (
            <div>
              <video
                ref={videoRef}
                className={videoAskPreviewAvatarVideoPreview}
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={undefined}
                src={
                  status !== "recording"
                    ? mediaBlobUrl ?? brokerVideoSourceString
                    : undefined
                }
                style={{ objectPosition: "50% 50% !important" }}
              ></video>
            </div>
          ) : (
            <img
              className={videoAskPreviewAvatarVideoPreview}
              src={brokerProfilePhoto}
              alt=""
            ></img>
          )}
        </div>
      </button>
      <button
        onClick={() =>
          status !== "recording" ? startRecording() : stopRecording()
        }
        style={{
          background: "none",
          border: "none",
          position: "absolute",
          right: 0,
          bottom: 0,
          cursor: "pointer",
          padding: 0,
        }}
      >
        <div
          style={{
            padding: "6px",
            border: "2px solid black",
            position: "relative",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            background: "#FFF",
          }}
        >
          <div className={recordVideoIconButton}></div>
        </div>
      </button>
    </div>
  );
};
