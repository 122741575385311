import { FC } from "react";
import { ITotalCostAnalysisReportPageProps } from "./types";
import { morfiLightGreen, morfiLightPurple } from "../../../tokens.css";
import { Banner } from "../../organisms/Banner/Banner";
import HistoricalInterestRateChartTile from "../../organisms/HistoricalInterestRateChart/HistoricalInterestRateChart";
import { mortgageRatesUs30Yr } from "../../organisms/HistoricalInterestRateChart/mock";
import { orangeCountyHistoricalHomePrices } from "../../organisms/HistoricalHomePriceChart/mock";
import { EarlyLoanPayoffTile } from "../../organisms/EarlyLoanPayoffTile/EarlyLoanPayoffTile";
import HistoricalHomePriceChartTile from "../../organisms/HistoricalHomePriceChart/HistoricalHomePriceChart";
import { MonthlyPaymentBarChart } from "../../organisms/MonthlyPaymentBarChart/MonthlyPaymentBarChart";
import { barChartDataValueMocks } from "../../organisms/MonthlyPaymentBarChart/mocks";
import { TotalPaymentAmountTile } from "../../organisms/TotalPaymentAmountTile/TotalPaymentAmountTile";
import { totalPaymentBarChartMock } from "../../organisms/TotalPaymentAmountTile/mock";
import { MonthlyPaymentDonutChart } from "../../organisms/MonthlyPaymentDonutChart/MonthlyPaymentDonutChart";
import { BrokerInfoRightHandRail } from "../../organisms/BrokerInfoRightHandRail/BrokerInfoRightHandRail";
import { ScenarioHighlightTile } from "../../organisms/ScenarioHighlightTile/ScenarioHighlightTile";
import { CreateScenarioButtonTile } from "../../molecules/CreateScenarioButtonTile/CreateScenarioButtonTile";
import { TotalCostAnalysisReportTemplate } from "../../templates/TotalCostAnalysisReportTemplate/TotalCostAnalysisReportTemplate";
import {
  getScenarioSummaryDescription,
  getEarlyPayoffSummaryDescription,
  getScenarioPreviewTiles,
} from "./utils";

export const TotalCostAnalysisReportPage: FC<
  ITotalCostAnalysisReportPageProps
> = ({
  primaryColor,
  accentColor,
  usersName,
  onStartScenarioCreation,
  tcaReport,
  activeScenarioId,
  brokerName,
  brokerCompanyLogo,
  brokerCompanyName,
  brokerCompanyStreetAddress,
  brokerCompanyWebsite,
  brokerEmail,
  brokerPhoneNumber,
  brokerMessageToProspectiveBorrower,
  brokerProfilePhoto,
  brokerTagLine,
  brokerMetrics,
  borrowerName,
  onBrokerVideoNarrationPlay,
}) => {
  return (
    <TotalCostAnalysisReportTemplate
      horizontalBrokerInfoTile={
        <BrokerInfoRightHandRail
          brokerCompanyWebsite={brokerCompanyWebsite}
          brokerProfilePhoto={brokerProfilePhoto}
          brokerName={brokerName}
          brokerTagLine={brokerTagLine}
          brokerPhoneNumber={brokerPhoneNumber}
          brokerEmail={brokerEmail}
          brokerMetrics={brokerMetrics}
          brokerMessageToProspectiveBorrower={
            brokerMessageToProspectiveBorrower
          }
          brokerCompanyLogo={brokerCompanyLogo}
          brokerCompanyName={brokerCompanyName}
          brokerCompanyStreetAddress={brokerCompanyStreetAddress}
          borrowerName={brokerName}
          orientation={"horizontal"}
          reportVideoSource={tcaReport.videoNarrationUrl}
          onBrokerVideoNarrationPlay={onBrokerVideoNarrationPlay}
        />
      }
      scenarioSummaryBanner={
        <Banner
          title={"Scenario 1 Summary"}
          body={getScenarioSummaryDescription(tcaReport.scenarios[0])}
          backgroundColor={morfiLightPurple}
        />
      }
      earlyPayOffStrategyBanner={
        <Banner
          title="Early Payoff Strategy"
          body={getEarlyPayoffSummaryDescription(tcaReport.scenarios[0])}
          backgroundColor={morfiLightGreen}
        />
      }
      highlightScenarioTile={
        tcaReport?.scenarios?.[0] ? (
          <ScenarioHighlightTile
            lendingScenario={tcaReport.scenarios[0]}
            lendingScenarios={tcaReport.scenarios}
            accentColor={accentColor}
            primaryColor={primaryColor}
            isPreview={false}
          />
        ) : (
          <CreateScenarioButtonTile
            onCreateScenarioButtonClick={onStartScenarioCreation}
          />
        )
      }
      scenarioPreviewTiles={getScenarioPreviewTiles(
        tcaReport,
        onStartScenarioCreation
      )}
      monthlyPaymentDonutChart={
        <MonthlyPaymentDonutChart
          dataLabel={"Amount of dollars"}
          dataBackgroundColors={[
            "#38BDF8",
            "#656AE3",
            "RGBA(131, 136, 247, 1)",
            "RGBA(161, 166, 255, 1)",
            "RGBA(191, 196, 255, 1)",
            "RGBA(221, 226, 255, 1)",
          ]}
          dataValues={[
            { label: "Principle & Interest", value: 1073.64 },
            { label: "Taxes", value: 100 },
            { label: "Insurance", value: 100 },
            { label: "HOA Dues", value: 300 },
            { label: "PMI", value: 43 },
            { label: "Extra Payment", value: 230 },
          ]}
          centerText={"$1300"}
        />
      }
      largeVisualization={
        <MonthlyPaymentBarChart
          barChartDataValues={barChartDataValueMocks}
          initialPlaceHolder={"Monthly Payment"}
        />
      }
      visualizationTiles={[
        <TotalPaymentAmountTile
          principalCost={200000}
          interestCost={100000}
          barChartDataValues={totalPaymentBarChartMock}
        />,
        <HistoricalInterestRateChartTile
          title={"Historical Mortgage Interest Rates"}
          legendText={"30yr Fixed Rate"}
          data={mortgageRatesUs30Yr}
        />,
        <HistoricalHomePriceChartTile
          title={"Home Prices in Orange County, CA"}
          legendText={"Median Home Prices"}
          data={orangeCountyHistoricalHomePrices}
          zipCode={"00000"}
        />,
        <EarlyLoanPayoffTile
          initialLoanAmount={
            tcaReport?.scenarios?.[0]?.details?.loan?.loanAmount ?? 0
          }
          initialInterestRate={
            tcaReport?.scenarios?.[0]?.details?.loan?.interestRate ?? 0
          }
          initialLoanTermYears={
            tcaReport?.scenarios?.[0]?.details?.loan?.term ?? 0
          }
          additionalPaymentFieldLabelText={"Additional Monthly"}
        />,
        <EarlyLoanPayoffTile
          initialLoanAmount={
            tcaReport?.scenarios?.[0]?.details?.loan?.loanAmount ?? 0
          }
          initialInterestRate={
            tcaReport?.scenarios?.[0]?.details?.loan?.interestRate ?? 0
          }
          initialLoanTermYears={
            tcaReport?.scenarios?.[0]?.details?.loan?.term ?? 0
          }
          additionalPaymentFieldLabelText={"Lump Sum Addition"}
        />,
      ]}
      verticalBrokerInfoRail={
        <BrokerInfoRightHandRail
          brokerCompanyWebsite={brokerCompanyWebsite}
          brokerProfilePhoto={brokerProfilePhoto}
          brokerName={brokerName}
          brokerTagLine={brokerTagLine}
          brokerPhoneNumber={brokerPhoneNumber}
          brokerEmail={brokerEmail}
          brokerMetrics={brokerMetrics}
          brokerMessageToProspectiveBorrower={
            brokerMessageToProspectiveBorrower
          }
          brokerCompanyLogo={brokerCompanyLogo}
          brokerCompanyName={brokerCompanyName}
          brokerCompanyStreetAddress={brokerCompanyStreetAddress}
          borrowerName={borrowerName}
          orientation={"vertical"}
          reportVideoSource={tcaReport.videoNarrationUrl}
          onBrokerVideoNarrationPlay={onBrokerVideoNarrationPlay}
        />
      }
    />
  );
};
