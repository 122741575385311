import { ITCAReport, ILendingScenario } from "../../../types";
import { morfiLightPurple, morfiDarkPurple } from "../../../tokens.css";
import { CreateScenarioButtonTile } from "../../molecules/CreateScenarioButtonTile/CreateScenarioButtonTile";
import { ScenarioHighlightTile } from "../../organisms/ScenarioHighlightTile/ScenarioHighlightTile";

export const getScenarioPreviewTiles = (
  tcaReport: ITCAReport,
  onStartScenarioCreation: Function
): Array<React.ReactNode> => {
  const result = tcaReport.scenarios
    .slice(1)
    .map((scenario: ILendingScenario) => {
      return (
        <ScenarioHighlightTile
          lendingScenario={scenario}
          lendingScenarios={[]}
          accentColor={morfiLightPurple}
          primaryColor={morfiDarkPurple}
          isPreview={true}
        />
      );
    });

  if (tcaReport.scenarios.length < 7 && tcaReport.scenarios.length >= 1) {
    result.push(
      <CreateScenarioButtonTile
        onCreateScenarioButtonClick={onStartScenarioCreation}
      />
    );
  }
  return result;
};

export const getScenarioSummaryDescription = (scenario: ILendingScenario) => {
  return `The purchase price of the target property is <b>$${scenario?.details?.property?.purchasePrice}</b>. The loan amount will be <b>$${scenario?.details?.loan?.loanAmount}</b> at a <b>${scenario?.details?.loan?.interestRate}%</b> interest rate over <b>${scenario?.details?.loan?.term}</b> years. The total cost of the loan will be <b>$250,000</b> with <b>$150,000</b> of interest being paid over the term of the loan. Your out of pocket expenses will be <b>$26,000</b> due at closing.`;
};

export const getEarlyPayoffSummaryDescription = (
  scenario: ILendingScenario
) => {
  return `If you pay an additional <b>$${scenario?.details?.earlyPayoffStrategies?.[0]?.extraPaymentAmount}</b> every <b>${scenario?.details?.earlyPayoffStrategies?.[0]?.frequency}</b>, your monthly payment will be <b>$1,700</b>. Over the term of the loan, you will save <b>$101,468.77</b> and shorten the life of your loan by <b>14 years 10 months</b>.`;
};
