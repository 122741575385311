import React, { FC } from "react";
import { IScenarioHighlightTileProps } from "./types";
import {
  ScenarioHighlightTileContainer,
  scenarioHighlightDetailsSectionContainer,
  scenarioHighlightTileBadgeContainer,
  scenarioHightlightTileHeaderSection,
  selectActiveScenarioDropdownContainer,
} from "./ScenarioHighlightTile.css";
import { FormDropdownInput } from "../../molecules/FormDropdownInput/FormDropdownInput";
import { playfairDisplayHeavy, interNormal } from "../../../tokens.css";
import {
  getBadgeTextByScenarioLendingType,
  getScenarioHighlightDetailsByScenarioType,
} from "./utils";
import { Badge } from "../../atoms/Badge/Badge";

interface IScenarioDropDownOptionProps {
  scenarioName: string | React.ReactNode;
  scenarioDescription: string | React.ReactNode;
}

const ScenarioDropDownOption: FC<IScenarioDropDownOptionProps> = ({
  scenarioName,
  scenarioDescription,
}) => {
  return (
    <div>
      <span className={playfairDisplayHeavy}>{scenarioName}</span>
      <br></br>
      <span className={interNormal}>{scenarioDescription}</span>
    </div>
  );
};

export const ScenarioHighlightTile: FC<IScenarioHighlightTileProps> = ({
  lendingScenario,
  lendingScenarios,
  accentColor,
  primaryColor,
  isPreview,
  ...props
}) => {
  return (
    <div className={ScenarioHighlightTileContainer}>
      <div className={scenarioHightlightTileHeaderSection}>
        {!isPreview && (
          <div className={selectActiveScenarioDropdownContainer}>
            <FormDropdownInput
              initialPlaceholderValue={
                <ScenarioDropDownOption
                  scenarioName={`Scenario ${lendingScenario?.scenarioId}`}
                  scenarioDescription={`${lendingScenario?.scenarioDescription}`}
                />
              }
              dropdownOptions={lendingScenarios.map((scenario, idx) => {
                return (
                  <ScenarioDropDownOption
                    scenarioName={`Scenario ${scenario?.scenarioId}`}
                    scenarioDescription={`${scenario?.scenarioDescription}`}
                  />
                );
              })}
              parentCallback={() => {}}
            ></FormDropdownInput>
          </div>
        )}
        <div className={scenarioHighlightTileBadgeContainer}>
          <Badge
            text={getBadgeTextByScenarioLendingType(
              lendingScenario.scenarioType
            )}
            color={accentColor}
          ></Badge>
        </div>
      </div>
      <div className={scenarioHighlightDetailsSectionContainer}>
        {getScenarioHighlightDetailsByScenarioType(
          lendingScenario,
          primaryColor,
          accentColor
        )}
      </div>
    </div>
  );
};
